export function resizeImg(img: HTMLImageElement, targetWidth: number, targetHeight: number): Promise<string> {
  return new Promise<string>((resolve) => {
    const srcWidth = img.naturalWidth
    const srcHeight = img.naturalHeight
    const dataUrl = img.src
    resizeInternal(dataUrl, srcWidth, srcHeight, targetWidth, targetHeight, resolve)
  })
}

export function resizeDataUrl(
  dataUrl: string,
  srcWidth: number,
  srcHeight: number,
  targetWidth: number,
  targetHeight: number,
): Promise<string> {
  return new Promise<string>((resolve) => {
    resizeInternal(dataUrl, srcWidth, srcHeight, targetWidth, targetHeight, resolve)
  })
}

type StringResolver = (value?: string | PromiseLike<string> | undefined) => void

function resizeInternal(
  srcDataUrl: string,
  srcWidth: number,
  srcHeight: number,
  targetWidth: number,
  targetHeight: number,
  resolve: StringResolver,
) {
  console.log('resizing image', srcDataUrl.length)
  const tmp = new Image()
  // canvas, context, cW, cH;

  const type = 'image/jpeg'
  const quality = 0.92

  let cW = srcWidth
  let cH = srcHeight

  tmp.src = srcDataUrl
  tmp.onload = () => {
    const canvas = document.createElement('canvas')

    // cW /= 2
    // cH /= 2

    // if (cW < targetWidth) cW = targetWidth
    // if (cH < targetHeight) cH = targetHeight

    cW = Math.max(targetWidth, Math.ceil(cW / 2))
    cH = Math.max(targetHeight, Math.ceil(cH / 2))

    canvas.width = cW
    canvas.height = cH
    const context = canvas.getContext('2d')
    if (context) {
      context.drawImage(tmp, 0, 0, cW, cH)

      const targetDataUrl = canvas.toDataURL(type, quality)

      if (cW <= targetWidth || cH <= targetHeight) {
        console.log('done resizing', targetDataUrl.length)
        resolve(targetDataUrl)
        return
      }

      tmp.src = targetDataUrl
    }
  }
}
