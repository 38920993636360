import React, { useEffect } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import ContentSqueezer from '../components/ContentSqueezer'
import Ballot from '../model/Ballot'
import { getCategoryDisplayText } from '../components/categories'
import { State } from '../store/State'
import { connect } from 'react-redux'
// import { UserCache } from '../firebase/UserCache'
import { Dispatch, Action, AnyAction } from 'redux'
import User, { CurrentUser, getAuthenticatedUser } from '../model/AuthenticatedUser'
import { ballotSubscriber } from '../firebase/ballotSubscriber'

import '../utils/animations.css'
import { UserVote, voteSubscriber } from '../firebase/currentVoteForBallot'
import { setVote, markBallotRead } from '../firebase'
import { useAlertDialog } from '../components/AlertDialog'
import { push } from 'connected-react-router'
import { authenticate } from '../firebase/auth'
import { UserAvatar } from '../components/UserAvatar'
import { Link } from 'react-router-dom'
import BallotButtons from '../components/BallotButtons'
import BallotQA, { BallotQA as BallotQAClass } from '../components/BallotQA'
import RichTextEditor from '../components/rte/MUIRichTextEditor'
import { shareOnFacebook } from '../components/facebook'
import UnreadItem from '../model/UnreadItems'

const useStyles = makeStyles(() => ({
  topStatus: {
    marginBottom: 10,
  },
  label: {
    fontWeight: 'bold',
    marginRight: 10,
  },
  ballotElement: {
    // marginLeft: 1,
    fontSize: '0.85rem',
  },
  description: {
    marginTop: 10,
  },
  containerDiv: {
    padding: 12,
    '& > *': { marginTop: 10 },
  },
  bottomButtonsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  divTable: {
    display: 'table',
    // width: 100%;
  },
  divTableRow: {
    display: 'table-row',
  },
  divTableCell: {
    display: 'table-cell',
    textAlign: 'center',
  },
}))

interface BallotViewProps {
  slug?: string
  ballot: Ballot | null
  // userCache: UserCache
  currentUser: CurrentUser
  isPreview: boolean
  startBallotSync?: (slug: string) => void
  stopBallotSync?: (slug: string) => void
  startVoteSync?: (ballotId: string) => void
  stopVoteSync?: (ballotId: string) => void
  setVote?: (ballotId: string, vote: boolean | null) => boolean
  dbVoteState?: UserVote | null
  authenticate: (postAuthActions: Action[]) => void
  createNavToCurrentLocationAction: () => Action
  unreadItems: UnreadItem[] | null
}

export const BallotView: React.FunctionComponent<BallotViewProps> = props => {
  console.log('is preview', props.isPreview)
  const { slug, ballot, startBallotSync, stopBallotSync, startVoteSync, stopVoteSync, currentUser, unreadItems } = props
  const ballotId = ballot ? ballot.id : undefined
  const userOrNull = getAuthenticatedUser(props.currentUser)
  const currentVoteFromDB = props.dbVoteState ? props.dbVoteState.vote : null
  console.log('current vote', props.dbVoteState, currentVoteFromDB)

  const dialog = useAlertDialog()

  const voteState = props.dbVoteState ? props.dbVoteState.vote : null

  const classes = useStyles(props)

  let qAndARef2: BallotQAClass | null = null

  useEffect(() => {
    if (slug && startBallotSync) {
      startBallotSync(slug)
      return () => {
        if (slug && stopBallotSync) {
          stopBallotSync(slug)
        }
      }
    }
  }, [slug, startBallotSync, stopBallotSync, currentUser])

  useEffect(() => {
    if (ballotId && startVoteSync) {
      startVoteSync(ballotId)
      return () => {
        if (ballotId && stopVoteSync) {
          stopVoteSync(ballotId)
        }
      }
    }
  }, [ballotId, startVoteSync, stopVoteSync, currentUser])

  useEffect(() => {
    if (unreadItems && ballot && ballot.id && userOrNull) {
      unreadItems.forEach(u => {
        if (u.ballotId === ballot.id) {
          markBallotRead({
            ballotId: ballot.id,
            uid: userOrNull.uid,
          })
        }
      })
    }
  }, [unreadItems])

  if (slug && ballot && slug !== ballot.slug) {
    return null
  }
  console.log('ballot', ballot)
  if (!ballot) {
    return null
  }
  function onAskQuestion() {
    if (!userOrNull) {
      void dialog
        .alert({
          message: 'You must sign in before asking a question.',
          cancelButton: true,
        })
        .then(result => {
          if (result) {
            props.authenticate([props.createNavToCurrentLocationAction()])
          }
        })
    } else {
      console.log('qAndA clicked', qAndARef2)
      if (qAndARef2) {
        qAndARef2.startEditing()
      }
    }
  }
  function onShareFacebook() {
    if (ballot && ballot.ogUrl) shareOnFacebook(ballot.ogUrl)
  }

  function onVote(direction: boolean) {
    if (!userOrNull) {
      void dialog
        .alert({
          message: 'You must sign in before voting.',
          cancelButton: true,
        })
        .then(result => {
          if (result) {
            props.authenticate([props.createNavToCurrentLocationAction()])
          }
        })
    } else {
      const newVote = voteState === direction ? null : direction
      if (ballot && ballot.id && props.setVote) {
        setVote(ballot.id, newVote)
      }
    }
  }

  let userDisplayName = 'unknown'
  let userProfileRoute = ''
  let userPhotoUrl = ''
  const authorUid: string | null = ballot.authorId
  // let author: User | null | undefined = null // getAuthenticatedUser(props.currentUser)
  if (authorUid === null) {
    // unpublished, authorId is current user if logged in
    const author = getAuthenticatedUser(props.currentUser)
    if (author === null) {
      userDisplayName = ''
    } else {
      userDisplayName = author.displayName || 'unknown'
      userPhotoUrl = author.photoUrl!
    }
  } else {
    userProfileRoute = `/profiles/${ballot.authorSlug}`
    userDisplayName = ballot.authorName || 'unknown'
    userPhotoUrl = ballot.authorPhotoUrl!
  }
  console.log('userProfileRoute', userProfileRoute)
  const uid = userOrNull ? userOrNull.uid : null
  const isOwner = userOrNull && authorUid === uid

  console.log('ballot author route', userProfileRoute)

  const buttonsDisabled = props.isPreview
  console.log('buttonsDisabled', buttonsDisabled, props.isPreview, props.currentUser)

  const isLoading = !props.isPreview && !ballot.id
  console.log('rendering ballot', isLoading, ballot.yays, ballot.nays)
  // quick hack to fix bouncing text
  const yaysText = isLoading ? undefined : '' + ballot.yays
  const naysText = isLoading ? undefined : '' + ballot.nays
  let ballotExtraInfo = getCategoryDisplayText(ballot.category)
  if (ballot.googlePlace) {
    ballotExtraInfo += ` | ${ballot.googlePlace.description}`
  }

  const createdAt = ballot.createdAt || new Date()
  console.log('ballot description', ballot.description)
  return (
    <React.Fragment>
      <ContentSqueezer>
        <Paper className={classes.containerDiv}>
          <div style={{ display: 'flex' }}>
            <Typography variant='h4' style={{ flexGrow: 1 }}>
              {ballot.headline}
            </Typography>
            {/* {isOwner && props.propId && renderEdit()} */}
            {/* {!isOwner && propId && <NotificationButton id={propId} />} */}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UserAvatar id='authorAvatar' displayName={userDisplayName} photoUrl={userPhotoUrl} to={userProfileRoute} />

            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, marginLeft: 8 }}>
              <Typography className={classes.ballotElement} id='authorDisplayName'>
                <Link to={userProfileRoute} className='plainLookingLinks'>
                  {userDisplayName}
                </Link>
              </Typography>
              <Typography id='ballotExtraInfo' className={classes.ballotElement}>
                {ballotExtraInfo}
              </Typography>
              <Typography className={classes.ballotElement}>{'Posted ' + moment(createdAt).fromNow()}</Typography>
            </div>
          </div>
          {ballot.photoUrl && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img alt='' src={ballot.photoUrl} style={{ maxHeight: 300 }} />
            </div>
          )}
          {/* <Typography variant='body1'>{ballot.description}</Typography> */}
          {ballot.description !== '' && <RichTextEditor readOnly value={ballot.description} />}
        </Paper>
        <BallotQA ballot={ballot} ref={c => (qAndARef2 = c as BallotQAClass)} />
      </ContentSqueezer>
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1 }}></div>
        <BallotButtons
          voteState={voteState}
          yaysText={yaysText}
          naysText={naysText}
          onVote={onVote}
          buttonsDisabled={buttonsDisabled}
          askDisabled={!!isOwner}
          onAskQuestion={onAskQuestion}
          onShareFacebook={onShareFacebook}
        />
        <div style={{ flexGrow: 1 }}></div>
      </div>
    </React.Fragment>
  )
}

function startBallotSync(slug: string, dispatch: Dispatch<Action>) {
  console.log('starting prop sync', slug)
  ballotSubscriber(slug).subscribe(dispatch)
}

function stopBallotSync(slug: string) {
  console.log('stopping prop sync', slug)
  ballotSubscriber(slug).unsubscribe()
}
function startVoteSync(ballotId: string, dispatch: Dispatch<Action>) {
  console.log('starting vote sync', ballotId)
  voteSubscriber(ballotId).subscribe(dispatch)
}

function stopVoteSync(ballotId: string) {
  console.log('stopping vote sync', ballotId)
  voteSubscriber(ballotId).unsubscribe()
}

const mapStateToPropsPartial = (state: State) => ({
  // userCache: state.userCache,
  currentUser: state.user,
  isPreview: state.router.location.hash === '#preview',
  createNavToCurrentLocationAction: () => push(state.router.location),
  unreadItems: state.unreadItems,
})

const mapDispatchToPropsPartial = (dispatch: Dispatch<AnyAction>) => ({
  authenticate: (postAuthActions: Action[]) => authenticate(dispatch, postAuthActions),
})

export const BallotViewPartial = connect(mapStateToPropsPartial, mapDispatchToPropsPartial)(BallotView)

const mapStateToPropsFull = (state: State) => ({
  ballot: state.currentBallot,
  setVote,
  dbVoteState: state.userVote,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  startBallotSync: (slug: string) => startBallotSync(slug, dispatch),
  stopBallotSync: (slug: string) => stopBallotSync(slug),
  startVoteSync: (ballotId: string) => startVoteSync(ballotId, dispatch),
  stopVoteSync: (ballotId: string) => stopVoteSync(ballotId),
})

// TODO: how to add type saftey here
export default connect(mapStateToPropsFull, mapDispatchToProps)(BallotViewPartial)
