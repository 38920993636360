import * as React from 'react'
import { ContentState, ContentBlock } from 'draft-js'

import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'

const styles = () =>
  createStyles({
    root: {},
  })

interface ImageProps extends WithStyles<typeof styles> {
  block: ContentBlock
  contentState: ContentState
}

const Image: React.FC<ImageProps> = (props: ImageProps) => {
  const { url } = props.contentState.getEntity(props.block.getEntityAt(0)).getData()
  return <img src={url} alt='' className={props.classes.root} />
}

export default withStyles(styles, { withTheme: true })(Image)
