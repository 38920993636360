import React, { useEffect, useState } from 'react'
import { History, LocationListener } from 'history'
import { connect } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import routes, { routeData } from './routes'
import './App.css'
import MenuDrawer from './components/HamburgerMenu'

export interface AppProps {
  history: History
}

export const App: React.FC<AppProps> = ({ history }: AppProps) => {
  function isHomePage(path: string) {
    return path === '/'
  }

  const [onHomePage, setOnHomePage] = useState(isHomePage(history.location.pathname))

  const onMenuItemClicked = (id: string) => {
    console.log(`on menu item clicked ${id}`)
  }

  const locationListener: LocationListener = (location, action) => {
    // console.log('history change', location, action)
    setOnHomePage(isHomePage(location.pathname))
  }
  const historyUnsubscribe = history.listen(locationListener)

  useEffect(() => {
    return () => {
      historyUnsubscribe()
    }
  })

  return (
    <ConnectedRouter history={history}>
      {onHomePage ? (
        routes
      ) : (
        <MenuDrawer menuItems={routeData} onMenuItemClicked={onMenuItemClicked}>
          {routes}
        </MenuDrawer>
      )}
    </ConnectedRouter>
  )
}

export default connect()(App)
