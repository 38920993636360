import React from 'react'
import GoogleMapsLocationSearch, { GooglePlace } from '../components/GoogleMapsLocationSearch'

interface GoogleMapProps {
  initialValue?: GooglePlace
  onValueChange: (place: GooglePlace | null) => void
}

class GoogleMap extends React.Component<GoogleMapProps> {
  // const classes = useStyles()

  mapRef = React.createRef<HTMLDivElement>()
  map?: google.maps.Map
  placesService?: google.maps.places.PlacesService
  marker?: google.maps.Marker

  componentDidMount() {
    console.log('componentDidMount', this.mapRef.current)
    if (this.mapRef.current) {
      this.map = new google.maps.Map(this.mapRef.current, {
        center: {
          lat: 37.09024,
          lng: -95.71289100000001,
        },
        zoom: 3,
        disableDefaultUI: true,
      })
      this.placesService = new google.maps.places.PlacesService(this.map)
      this.marker = new google.maps.Marker({
        map: this.map,
        visible: false,
      })
      if (this.props.initialValue) {
        this.updateMap(this.props.initialValue.place_id)
      }
    }
  }

  onAutoCompleteChange = (place: GooglePlace | null) => {
    console.log('google place', place)
    if (place) {
      this.props.onValueChange(place)
      this.updateMap(place.place_id)
    }
  }

  updateMap(placeId: string) {
    let markerSet = false
    if (this.placesService) {
      const request: google.maps.places.PlaceDetailsRequest = { placeId }
      this.placesService.getDetails(request, (result, status) => {
        console.log('got details', result, status)
        const { geometry } = result
        if (status === google.maps.places.PlacesServiceStatus.OK && geometry && this.map) {
          console.log('geometry', geometry.location.lat(), geometry.location.lng())
          if (geometry.viewport) {
            this.map.fitBounds(geometry.viewport)
          } else {
            this.map.setCenter(geometry.location)
            this.map.setZoom(17) // Why 17? Because it looks good.
          }
          if (this.marker) {
            this.marker.setPosition(geometry.location)
            this.marker.setVisible(true)
            markerSet = true
          }
        }
      })
    }
    if (!markerSet && this.marker) {
      this.marker.setVisible(false)
    }
  }

  render() {
    return (
      <div style={{ paddingTop: 5, paddingBottom: 5 }}>
        <GoogleMapsLocationSearch
          fullWidth
          // className={classes.formField}
          label='Location'
          placeholder='Search for a location...'
          initialValue={this.props.initialValue}
          onValueChange={this.onAutoCompleteChange}
          // onValueChange={value => {
          //   console.log('google location value change', value)
          // }}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div id='map' style={{ width: '100%', height: 300, marginTop: 8 }} ref={this.mapRef}></div>
        </div>
      </div>
    )
  }
}

export default GoogleMap
