import React from 'react'
import { State } from '../store/State'
import { connect } from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import AccountCircle from '@material-ui/icons/AccountCircle'
import User, { getAuthenticatedUser } from '../model/AuthenticatedUser'
import RouteIconButton from '../components/RouteIconButton'
export type MenuMouseEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>

interface UserAvatarProps {
  // user: User | undefined | null
  displayName?: string | null
  photoUrl?: string | null
  onClick?: (event: MenuMouseEvent) => void
  to?: string
  id?: string
}

export function getAvatar(photoUrl: string | null | undefined, displayName: string | null): JSX.Element {
  if (displayName && displayName !== '') {
    if (photoUrl) {
      return <Avatar alt={displayName} src={photoUrl} />
    }
    return <Avatar>{displayName.charAt(0)}</Avatar>
  }
  return <AccountCircle />
}

export const UserAvatar: React.FunctionComponent<UserAvatarProps> = props => {
  console.log('UserAvatar', props)

  if (!props.displayName) {
    return <React.Fragment />
  }

  const avatar = getAvatar(props.photoUrl, props.displayName)

  return (
    <React.Fragment>
      <RouteIconButton
        aria-label='account of current user'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        onClick={props.onClick}
        color='inherit'
        to={props.to}
        id={props.id}
      >
        {avatar}
      </RouteIconButton>
      {/* <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleProfile}>Profile</MenuItem>
        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
      </Menu> */}
    </React.Fragment>
  )
}

const mapStateToProps = (state: State) => {
  const currentUser: User | undefined | null = getAuthenticatedUser(state.user)
  return {
    displayName: currentUser ? currentUser.displayName : null,
    photoUrl: currentUser ? currentUser.photoUrl : null,
  }
}

export const CurrentUserAvatar = connect(mapStateToProps)(UserAvatar)
