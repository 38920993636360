import React from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router'
import NoMatch from '../components/NoMatch'

import Home from '../screens/Home'
import Explore from '../screens/Explore'
import MyBallots from '../screens/MyBallots'
import BallotCreate from '../screens/BallotCreate'
import Profile from '../screens/Profile'
import SignIn from '../screens/SignIn'
import ReduxExample from '../screens/ReduxExample'
import ReactRouterExample from '../screens/ReactRouterExample'
import BallotView from '../screens/BallotView'
import UnreadItemsScreen from '../screens/UnreadItems'
// import { userCacheImpl } from '../firebase/UserCache'

export interface RouteData {
  id: string
  displayText: string
  route: string
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  render?: (props: any) => JSX.Element
}

export const routeData: RouteData[] = [
  {
    id: 'home',
    displayText: '',
    route: '/',
    component: Home,
  },
  {
    id: 'myBallots',
    displayText: 'My Ballots',
    route: '/my-ballots',
    component: MyBallots,
  },
  {
    id: 'explore',
    displayText: 'Explore',
    route: '/explore',
    component: Explore,
  },
  {
    id: 'createBallot',
    displayText: 'Create Ballot',
    route: '/create-ballot',
    component: BallotCreate,
  },
  {
    id: 'signIn',
    displayText: '',
    route: '/signin',
    component: SignIn,
  },
  {
    id: 'unreadItems',
    displayText: '',
    route: '/unread-items',
    component: UnreadItemsScreen,
  },
]

if (process.env.NODE_ENV !== 'production') {
  routeData.splice(
    routeData.length - 1,
    0,
    {
      id: 'reduxExample',
      displayText: 'Redux Example',
      route: '/redux-example',
      component: ReduxExample,
    },
    {
      id: 'reactRouterExample',
      displayText: 'React-Router Example',
      route: '/react-router-example',
      component: ReactRouterExample,
    },
  )
}

const routes = (
  <React.Fragment>
    {/* <NavBar /> */}
    <Switch>
      {routeData.map(rd => {
        if (rd.route === '/') {
          return <Route key={`rr ${rd.route}`} exact path={rd.route} component={rd.component} />
        }
        const render = rd.render
        if (render) {
          return <Route key={`rr ${rd.route}`} exact path={rd.route} render={props => render(props)} />
        }
        return <Route key={`rr ${rd.route}`} exact path={rd.route} component={rd.component} />
      })}
      <Route
        key={`rr Ballot`}
        path='/ballots/:slug'
        render={props => {
          return <BallotView slug={props.match.params.slug} />
        }}
      />
      <Route
        key={`rr Profile`}
        path='/profiles/:uid'
        render={props => {
          // void userCacheImpl.fetchUser(props.match.params.uid)
          return <Profile type='view' viewUserId={props.match.params.uid} />
        }}
      />
      <Route key={`rr Profile`} path='/profile' render={() => <Profile type='edit' />} />

      <Route component={NoMatch} />
    </Switch>
  </React.Fragment>
)

export default routes
