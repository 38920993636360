import deepEqual from 'deep-equal'

export class Debounce<T> {
  // private value?: string
  private timerId?: NodeJS.Timeout
  constructor(private readonly getter: () => T, private readonly callback: (value: T) => void, private readonly delay = 3000) {}
  setValue(newValue: T) {
    this.cancel()
    this.timerId = setTimeout(() => {
      const currentValue = this.getter()
      if (deepEqual(newValue, currentValue)) {
        this.callback(newValue)
      }
    }, this.delay)
  }
  cancel() {
    if (this.timerId) {
      clearTimeout(this.timerId)
      this.timerId = undefined
    }
  }
}
