import React from 'react'
import Button, { ButtonTypeMap, ButtonProps } from '@material-ui/core/Button'
import { ExtendButtonBase } from '@material-ui/core/ButtonBase'
import { signIn as firebaseSignIn } from '../firebase/auth'
import { State } from '../store/State'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'
import { Location } from 'history'

interface SignInButtonProps extends DispatchProp {
  signIn: (location: Location, dispatch: Dispatch<AnyAction>) => void
  location: Location
}

type Props = ButtonProps<'button', SignInButtonProps>

const SignInButton: ExtendButtonBase<ButtonTypeMap<SignInButtonProps, 'button'>> = (props: Props) => {
  function handleClick() {
    props.signIn(props.location, props.dispatch)
  }

  const { signIn, dispatch, ...buttonProps } = props
  return (
    <Button id='signIn' {...buttonProps} onClick={handleClick}>
      {props.children}
    </Button>
  )
}

const mapStateToProps = (state: State) => ({
  signIn: firebaseSignIn,
  location: state.router.location,
})

export default connect(mapStateToProps)(SignInButton)
