import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { State } from '../store/State'
import { connect, useDispatch } from 'react-redux'
import { CurrentUser, getUidOrNull } from '../model/AuthenticatedUser'
import { CurrentUserAvatar, MenuMouseEvent } from './UserAvatar'
import SignInButton from './SignInButton'
import { signOut } from '../firebase/auth'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { replace } from 'connected-react-router'
import ChatOutlined from '@material-ui/icons/ChatOutlined'
import '../utils/animations.css'
import { unreadItemslSubscriber } from '../firebase/unreadItemsSubscriber'
import { Dispatch, Action, AnyAction } from 'redux'
import RouteIconButton from './RouteIconButton'
import Badge from '@material-ui/core/Badge'

const useStyles = makeStyles({
  badge: {
    backgroundClip: 'red',
  },
})

interface UnreadIndicatorProps {
  user: CurrentUser
  unreadItemCount: number
  isOnSignInPage: boolean
  startUnreadItemsSync: (uid: string) => void
  stopUnreadItemsSync: (uid: string) => void
}

const UnreadItemsIndicator: React.FunctionComponent<UnreadIndicatorProps> = props => {
  const classes = useStyles()
  // console.log('AppBarUser', props)
  const uid = getUidOrNull(props.user)

  useEffect(() => {
    if (uid) {
      props.startUnreadItemsSync(uid)
      return () => {
        if (uid) {
          props.startUnreadItemsSync(uid)
        }
      }
    }
  }, [uid])

  switch (props.user) {
    case null:
      return null
    case 'unknown':
      return null
    default:
      if (props.unreadItemCount === 0) {
        return null
      }
      return (
        <RouteIconButton
          aria-label='account of current user'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          // onClick={props.onClick}
          color='inherit'
          to='/unread-items'
        >
          <ChatOutlined className='repeatingPulse' />
        </RouteIconButton>
      )
  }
}

function startUnreadItemsSync(slug: string, dispatch: Dispatch<Action>) {
  console.log('starting unread items sync', slug)
  unreadItemslSubscriber(slug).subscribe(dispatch)
}

function stopUnreadItemsSync(slug: string) {
  console.log('stopping unread items sync', slug)
  unreadItemslSubscriber(slug).unsubscribe()
}

const mapStateToProps = (state: State) => ({
  user: state.user,
  isOnSignInPage: state.router.location.pathname === '/signin',
  unreadItemCount: state.unreadItems ? state.unreadItems.length : 0,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  startUnreadItemsSync: (uid: string) => startUnreadItemsSync(uid, dispatch),
  stopUnreadItemsSync,
})

export default connect(mapStateToProps, mapDispatchToProps)(UnreadItemsIndicator)
