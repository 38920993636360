type ParsedQueryParameters = { [key: string]: string | undefined }

export function parseUrlQuery(queryString: string): ParsedQueryParameters {
  if (queryString === '') {
    return {}
  }
  var query: ParsedQueryParameters = {}
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

export function toUrlQuery(query: ParsedQueryParameters): string {
  const pairs = Object.keys(query).map(key => {
    const value = query[key]
    return value ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}` : key
  })
  return pairs.length === 0 ? '' : pairs.join('&')
}
