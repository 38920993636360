const facebookConnectPlugin = (window as any).facebookConnectPlugin

export function shareOnFacebook(ogUrl: string) {
  const shareInfo: any = {
    method: 'share',
    href: ogUrl,
  }

  function onSuccess(data: any) {
    console.log('shareOnFacebook success', JSON.stringify(data))
  }

  function onError(data: any) {
    console.log('shareOnFacebook error', JSON.stringify(data))
  }

  if (facebookConnectPlugin) {
    console.log('calling facebookConnectPlugin', JSON.stringify(shareInfo))
    facebookConnectPlugin.showDialog(shareInfo, onSuccess, onError)
  } else {
    console.log('calling FB.ui', shareInfo)
    FB.ui(
      {
        method: 'share',
        href: ogUrl,
      },
      onSuccess,
    )
  }
}
