import React from 'react'
import TextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField'
import Downshift, { ControllerStateAndHelpers } from 'downshift'
import Paper from '@material-ui/core/Paper'
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'

export interface AutoCompleteProps<T> {
  valueProvider: (inputValue: string) => Promise<T[]>
  onValueChange: (value: T | null) => void
  initialValue?: T
  displayTextGetter: (t: T) => string
}

interface RenderSuggestionProps<T> {
  highlightedIndex: number | null
  index: number
  itemProps: MenuItemProps<'div', { button?: never }>
  selectedItem: T | null
  item: T
}

interface AutoCompleteState<T> {
  inputValue: string
  items: T[]
  selectedItem: T | null
}

// export type AutoCompleteProps<T> = Props<T> & Omit<OutlinedTextFieldProps, 'variant'>

export default class AutoComplete<T> extends React.Component<
  AutoCompleteProps<T> & Omit<OutlinedTextFieldProps, 'variant'>,
  AutoCompleteState<T>
> {
  state = {
    inputValue: '',
    items: [],
    selectedItem: null,
  }

  inputRef = React.createRef<HTMLInputElement>()

  getSuggestions = (value: string) => {
    console.log('getSuggestions', value)
    if (value !== this.state.inputValue) {
      this.setState({ inputValue: value })
      void this.props.valueProvider(value).then(values => {
        if (this.state.inputValue === value) {
          this.setState({ items: values })
        }
      })
    }
  }

  renderSuggestion(suggestionProps: RenderSuggestionProps<T>) {
    const { item, index, itemProps, highlightedIndex, selectedItem } = suggestionProps
    const isHighlighted = highlightedIndex === index
    // const isSelected = (selectedItem || '').indexOf(item.value) > -1
    const isSelected = selectedItem === item

    console.log('itemProps', itemProps)
    const { onClick: downshiftOnClick } = itemProps
    console.log('wtf', downshiftOnClick)
    return (
      <MenuItem
        key={this.props.displayTextGetter(item)}
        selected={isHighlighted}
        component='div'
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
        {...itemProps}
      >
        {this.props.displayTextGetter(item)}
      </MenuItem>
    )
  }
  //   if (!downshift.inputValue || downshift.inputValue.length === 0) {
  //   downshift.clearSelection()
  // }

  onInputValueChanged = (value: string, stateAndHelpers: ControllerStateAndHelpers<T>) => {
    if (value && value.length > 0) {
      this.getSuggestions(value)
    } else {
      if (stateAndHelpers.selectedItem) {
        stateAndHelpers.clearSelection()
      }
    }
  }

  render() {
    const DS = Downshift
    return (
      <DS
        onInputValueChange={this.onInputValueChanged}
        onChange={selection => this.props.onValueChange(selection)}
        itemToString={item => (item ? this.props.displayTextGetter(item) : '')}
        initialSelectedItem={this.props.initialValue}
      >
        {downshift => {
          const { initialValue, displayTextGetter, valueProvider, onValueChange, ...providedTextFieldProps } = this.props
          const inputProps = downshift.getInputProps(providedTextFieldProps)
          const menuProps = downshift.getMenuProps()
          console.log('menuProps', menuProps)
          // this.getSuggestions(downshift.inputValue!)
          return (
            <div>
              <TextField {...inputProps} variant='outlined' inputRef={this.inputRef} autoComplete={'off'} />
              <div {...downshift.getMenuProps()}>
                {downshift.isOpen && this.state.items.length > 0 && (
                  // <Paper square style={{ marginTop: 8, width: this.inputRef.current ? this.inputRef.current.clientWidth : undefined }}>
                  <Paper>
                    <MenuList>
                      {this.state.items.map((item, index) =>
                        this.renderSuggestion({
                          item,
                          index,
                          itemProps: downshift.getItemProps({ item }),
                          highlightedIndex: downshift.highlightedIndex,
                          selectedItem: downshift.selectedItem,
                        }),
                      )}
                    </MenuList>
                  </Paper>
                )}
              </div>
            </div>
          )
        }}
      </DS>
    )
  }
}

// interface SomeItem extends ItemWithValue {
//   extra: string
// }

// const itemz: SomeItem[] = [
//   { extra: 'whatever', value: 'a' },
//   { extra: 'whatever', value: 'aa' },
//   { extra: 'whatever', value: 'ab' },
//   { extra: 'whatever', value: 'ac' },
//   { extra: 'whatever', value: 'Afghanistan' },
//   { extra: 'whatever', value: 'Aland Islands' },
//   { extra: 'whatever', value: 'Albania' },
//   { extra: 'whatever', value: 'Algeria' },
//   { extra: 'whatever', value: 'American Samoa' },
//   { extra: 'whatever', value: 'Andorra' },
//   { extra: 'whatever', value: 'Angola' },
//   { extra: 'whatever', value: 'Anguilla' },
//   { extra: 'whatever', value: 'Antarctica' },
//   { extra: 'whatever', value: 'Antigua and Barbuda' },
//   { extra: 'whatever', value: 'Argentina' },
//   { extra: 'whatever', value: 'Armenia' },
//   { extra: 'whatever', value: 'Aruba' },
//   { extra: 'whatever', value: 'Australia' },
//   { extra: 'whatever', value: 'Austria' },
//   { extra: 'whatever', value: 'Azerbaijan' },
//   { extra: 'whatever', value: 'Bahamas' },
//   { extra: 'whatever', value: 'Bahrain' },
//   { extra: 'whatever', value: 'Bangladesh' },
//   { extra: 'whatever', value: 'Barbados' },
//   { extra: 'whatever', value: 'Belarus' },
//   { extra: 'whatever', value: 'Belgium' },
//   { extra: 'whatever', value: 'Belize' },
//   { extra: 'whatever', value: 'Benin' },
//   { extra: 'whatever', value: 'Bermuda' },
//   { extra: 'whatever', value: 'Bhutan' },
//   { extra: 'whatever', value: 'Bolivia, Plurinational State of' },
//   { extra: 'whatever', value: 'Bonaire, Sint Eustatius and Saba' },
//   { extra: 'whatever', value: 'Bosnia and Herzegovina' },
//   { extra: 'whatever', value: 'Botswana' },
//   { extra: 'whatever', value: 'Bouvet Island' },
//   { extra: 'whatever', value: 'Brazil' },
//   { extra: 'whatever', value: 'British Indian Ocean Territory' },
//   { extra: 'whatever', value: 'Brunei Darussalam' },
// ]

// async function valueGetter(value: string) {
//   await sleep(2000)
//   let count = 0
//   const limit = 5
//   const inputValue = deburr(value.trim()).toLowerCase()
//   const inputLength = inputValue.length

//   const filteredItems = itemz.filter((suggestion) => {
//     const keep = count < limit && suggestion.value.slice(0, inputLength).toLowerCase() === inputValue
//     if (keep) {
//       count += 1
//     }
//     return keep
//   })
//   return filteredItems
// }

// const Wrapper: React.FC<Omit<OutlinedTextFieldProps, 'variant'>> = (props) => {
//   return <AutoComplete valueProvider={valueGetter} onValueChange={(item: SomeItem | null) => console.log('item', item)} />
// }

// // export default Wrapper
