import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

// tslint:disable-next-line: no-empty-interface
interface LoadingScreenProps {}

const LoadingScreen: React.FunctionComponent<LoadingScreenProps> = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress />
    </div>
  )
}

export default LoadingScreen
