import * as React from 'react'
import { ContentState } from 'draft-js'

interface LinkProps {
  children?: React.ReactNode
  contentState: ContentState
  entityKey: string
}

const Link: React.FC<LinkProps> = (props: LinkProps) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData()
  return (
    <a
      href={url}
      style={{
        textDecoration: 'underline',
        color: 'inherit',
      }}
      className='editor-anchor'
      target='_blank'
      rel='noopener noreferrer'
    >
      {props.children}
    </a>
  )
}

export default Link
