import React from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { Link } from 'react-router-dom'

import BallotQuery from '../model/BallotQuery'
import { connect } from 'react-redux'
import { State } from '../store/State'
import { CurrentUser, UNKNOWN_AUTH } from '../model/AuthenticatedUser'
import RouteButton from '../components/RouteButton'
import SignInButton from '../components/SignInButton'
import BallotListView from '../components/BallotListView'
import ContentSqueezer from '../components/ContentSqueezer'
import UnreadItem from '../model/UnreadItems'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  queryResults: {
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center',
    padding: 20,
  },
  ballotItem: {
    margin: 10,
    padding: 10,
  },
  plainLookingLinks: {
    textDecoration: 'none',
    color: 'inherit',
  },
  deemphasized: {
    fontSize: '0.85rem',
    color: '#666',
    marginLeft: 5,
  },
})

interface UnreadItemsProps {
  currentUser: CurrentUser
  unreadItems: UnreadItem[] | null
}

const UnreadItemsScreen: React.FunctionComponent<UnreadItemsProps> = props => {
  const classes = useStyles({})

  function renderNoUnreadItems() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 20 }}>
        <div style={{ margin: 10 }}>
          <Typography>You don't have any unread items.</Typography>
        </div>
      </div>
    )
  }

  function renderUnreadItem(unreadItem: UnreadItem) {
    var typeWord = unreadItem.type === 'question' ? 'question' : 'answer'
    if (unreadItem.count > 1) {
      typeWord += 's'
    }
    return (
      <Paper key={unreadItem.ballotId} className={classes.ballotItem}>
        <Link to={`/ballots/${unreadItem.slug}`} className={classes.plainLookingLinks}>
          <Typography variant='h6'>{unreadItem.headline}</Typography>
          <Typography className={classes.deemphasized}>{`${unreadItem.count} unread ${typeWord}`}</Typography>
        </Link>
      </Paper>
    )
  }

  function renderUnreadItems() {
    return (
      <div className={classes.queryResults}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Typography>Unread Questions and Answers</Typography>
        </div>

        {props.unreadItems?.map(renderUnreadItem)}
      </div>
    )
  }

  function renderUnreadItemsWhileUnauthenticated() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 20 }}>
        <div style={{ margin: 10 }}>
          <Typography>You must sign in before viewing your list of unread items.</Typography>
        </div>
        <div style={{ margin: 10 }}>
          <SignInButton variant='contained'>Sign In</SignInButton>
        </div>
      </div>
    )
  }

  if (props.currentUser === UNKNOWN_AUTH) {
    return null
  }

  if (props.currentUser === null) {
    return renderUnreadItemsWhileUnauthenticated()
  }

  return (
    <ContentSqueezer>
      {!props.unreadItems || (props.unreadItems.length === 0 && renderNoUnreadItems())}
      {props.unreadItems && props.unreadItems.length > 0 && renderUnreadItems()}
    </ContentSqueezer>
  )
}

const mapStateToProps = (state: State) => ({
  currentUser: state.user,
  unreadItems: state.unreadItems,
})

export default connect(mapStateToProps)(UnreadItemsScreen)
