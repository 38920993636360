type syncCleanupTask = () => void
type asyncCleanupTask = () => Promise<void>
type cleanupTask = syncCleanupTask | asyncCleanupTask

const cleanupTasks: cleanupTask[] = []

export function registerCleanupTask(task: cleanupTask) {
  cleanupTasks.push(task)
}

function executeCleanupTasks() {
  console.log('cleaning up')
  cleanupTasks.forEach((task) => {
    task()
  })
}

export function unregisterCleanupTask(task: cleanupTask) {
  const index = cleanupTasks.indexOf(task)
  if (index !== -1) {
    console.log('found cleanup task, removing from cleanupTasks')
    cleanupTasks.splice(index, 1)
  } else {
    console.log('cleanupTasks could not find cleanup task')
  }
}

window.addEventListener('beforeunload', executeCleanupTasks)
