import React, { useState, useContext } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

interface AlertDialogProps {
  title?: string
  message?: string
  cancelButton?: boolean
  onClose: (action?: string) => void
}

const AlertDialog: React.FunctionComponent<AlertDialogProps> = props => {
  return (
    <React.Fragment>
      <Dialog
        open={true}
        onClose={() => props.onClose('')}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        {props.title && <DialogTitle id='alert-dialog-title'>{props.title}</DialogTitle>}
        {props.message && (
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>{props.message}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          {props.cancelButton && (
            <Button onClick={e => props.onClose('cancel')} color='primary'>
              Cancel
            </Button>
          )}

          <Button onClick={() => props.onClose('ok')} color='primary' autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default AlertDialog

type Resolve<T> = (value?: T | PromiseLike<T>) => void

// createContext + typescript means you have to provide a default, even when it doesn't make sense
function createContextNoDefault<T>(_?: T) {
  return React.createContext<T>((undefined as any) as T)
}

type DialogOptions = Omit<AlertDialogProps, 'onClose'>

type ShowDialogFunction = (param: DialogOptions) => Promise<boolean>

interface DialogType {
  alert: ShowDialogFunction
}

// if you put a bare function into useState, it gets called by react!
interface DialogFinalize {
  resolve: Resolve<boolean>
}

const AlertDialogContext = createContextNoDefault<DialogType>()

export const AlertDialogProvider: React.FunctionComponent<{}> = props => {
  const [show, setShow] = useState(false)
  const [dialogFinalize, setDialogFinalize] = useState<DialogFinalize | null>(null)
  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({})

  const alert: ShowDialogFunction = (options: DialogOptions) => {
    const promise = new Promise<boolean>((resolve, reject) => {
      setShow(true)
      setDialogOptions(options)
      setDialogFinalize({ resolve })
      console.log('set resolve')
    })
    return promise
  }

  const onCloseAlert = (result?: string) => {
    setShow(false)
    if (dialogFinalize) {
      dialogFinalize.resolve(result === 'ok')
      console.log('setting resolve to null')
      setDialogFinalize(null)
    }
  }

  return (
    <AlertDialogContext.Provider value={{ alert }}>
      {props.children}
      {show && <AlertDialog {...dialogOptions} onClose={onCloseAlert} />}
    </AlertDialogContext.Provider>
  )
}

export function useAlertDialog() {
  return useContext(AlertDialogContext)
}
