import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { BallotList, ballotListSubscriber } from '../firebase/ballotList'
import { getAvatar } from './UserAvatar'
import { getCategoryDisplayText } from './categories'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import AnimatedTypography from './AnimatedTypography'
// import { UserCache } from '../firebase/UserCache'
import moment from 'moment'
import { State } from '../store/State'
import { connect } from 'react-redux'
import BallotQuery from '../model/BallotQuery'
import { CurrentUser } from '../model/AuthenticatedUser'
import { Dispatch } from 'redux'
import { push } from 'connected-react-router'
import Ballot from '../model/Ballot'
import algoliasearch from 'algoliasearch'
import { SetValueAction } from '../firebase/dbSubscriptions'

const useStyles = makeStyles({
  ballotItem: {
    margin: 10,
    padding: 10,
  },
  plainLookingLinks: {
    textDecoration: 'none',
    color: 'inherit',
  },
  deemphasized: {
    fontSize: '0.85rem',
    color: '#666',
    marginLeft: 5,
  },
})

interface BallotListProps {
  ballotQuery: BallotQuery
  ballotList: BallotList | null
  // userCache: UserCache
  currentUser: CurrentUser
  subscribeToBallotList: (ballotQuery: BallotQuery) => void
  unsubscribeToBallotList: (ballotQuery: BallotQuery) => void
  renderEmptyList?: () => JSX.Element
  navigateToAuthor: (authorSlug: string | null) => void
}

const BallotListView: React.FunctionComponent<BallotListProps> = props => {
  const classes = useStyles({})

  const { ballotQuery, subscribeToBallotList, unsubscribeToBallotList, currentUser } = props
  const queryAsString = JSON.stringify(ballotQuery)
  useEffect(() => {
    const query = JSON.parse(queryAsString)
    if (!query) {
      // console.log('not subscribing to ballots until auth resolves')
      return
    }
    // console.log('subscribing to ballot query', query)
    subscribeToBallotList(query)
    return () => {
      if (!query) {
        // console.log('not unsubscribing as ballot query is null')
        return
      }
      // console.log('explore cleanup, unsubscribing', query)
      unsubscribeToBallotList(query)
    }
  }, [queryAsString, currentUser, subscribeToBallotList, unsubscribeToBallotList])

  let ballotElements: JSX.Element[] | undefined
  const { ballotList } = props

  if (ballotList === null) {
    console.log('null ballot list')
    return null
  }

  if (Object.keys(ballotList).length === 0 && props.renderEmptyList) {
    return props.renderEmptyList()
  }
  ballotElements = Object.keys(ballotList).map(ballotId => {
    const p = ballotList[ballotId]
    const authorDisplayName = p.authorName || 'unknown'
    // const authorText = author ? `From: ${author.displayName}` : 'From:'
    const photoUrl = p.authorPhotoUrl || null
    const avatar = getAvatar(photoUrl, authorDisplayName)
    const createdAt = p.createdAt || new Date()
    let ballotExtraInfo = getCategoryDisplayText(p.category)
    if (p.googlePlace) {
      ballotExtraInfo += ` | ${p.googlePlace.description}`
    }

    return (
      <Paper key={ballotId} className={classes.ballotItem}>
        <Link to={`/ballots/${p.slug}`} className={classes.plainLookingLinks}>
          <Typography variant='h6'>{p.headline}</Typography>
          <div style={{ display: 'flex', alignItems: 'center', paddingTop: 5 }}>
            <div
              onClick={e => {
                e.preventDefault()
                props.navigateToAuthor(p.authorSlug)
              }}
            >
              {avatar}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, marginLeft: 8 }}>
              <Typography style={{ marginLeft: 5 }} className={classes.deemphasized}>
                {authorDisplayName}
              </Typography>
              <Typography style={{ marginLeft: 5 }} className={classes.deemphasized}>
                {ballotExtraInfo}
              </Typography>
              <Typography style={{ marginLeft: 5 }} className={classes.deemphasized}>
                {'Posted ' + moment(createdAt).fromNow()}
              </Typography>
            </div>
            <ThumbUpIcon fontSize='small' className={classes.deemphasized} />
            <AnimatedTypography className={classes.deemphasized} text={'' + p.yays} />
            <ThumbDownIcon className={classes.deemphasized} />
            <AnimatedTypography className={classes.deemphasized} text={'' + p.nays} />
          </div>
        </Link>
      </Paper>
    )
  })
  return <React.Fragment>{ballotElements}</React.Fragment>
}

function navigateToAuthor(authorSlug: string | null, dispatch: Dispatch) {
  const authorRoute = authorSlug ? `/profiles/${authorSlug}` : ''
  dispatch(push(authorRoute))
}

const mapStateToProps = (state: State) => {
  return {
    ballotList: state.ballotList,
    // userCache: state.userCache,
    currentUser: state.user,
  }
}

const ALGOLIA_APP_ID = 'RJ4K01I0KL'
const ALGOLIA_ADD_DELETE_API_KEY = '4415990767e5fe73b5d8d1c8ae7550ba'

async function queryAlgolia(dispatch: Dispatch, ballotQuery: BallotQuery) {
  console.log('searching', ballotQuery)
  const client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_ADD_DELETE_API_KEY)
  const index = client.initIndex('ballots')
  const searchText = ballotQuery.searchText || ''
  console.log('submitting query')
  void index.search(searchText, { hitsPerPage: 100 }).then(result => {
    console.log('query result', result)
    console.log(result)
    const ballotList: BallotList = {}

    for (const hit of result.hits) {
      const ballot: Ballot = (hit as unknown) as Ballot
      ballotList[hit.objectID] = ballot
    }
    const setBallotListAction: SetValueAction<BallotList> = {
      type: 'PROPOSAL_LIST',
      value: ballotList,
    }
    dispatch(setBallotListAction)
  })
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    subscribeToBallotList: (ballotQuery: BallotQuery) => ballotListSubscriber(ballotQuery).subscribe(dispatch),
    unsubscribeToBallotList: (ballotQuery: BallotQuery) => ballotListSubscriber(ballotQuery).unsubscribe(),
    // subscribeToBallotList: (ballotQuery: BallotQuery) => queryAlgolia(dispatch, ballotQuery),
    // unsubscribeToBallotList: (ballotQuery: BallotQuery) => {},
    navigateToAuthor: (authorSlug: string | null) => navigateToAuthor(authorSlug, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BallotListView)
