import firebase from 'firebase/app'
import 'firebase/firestore'
import Ballot from '../model/Ballot'
import { SubscriberReturnValue, dbSubscriber } from './dbSubscriptions'
import { QuestionAndAnswer, QuestionAndAnswers } from '../model/QuestionAndAnswer'

function qandaTransforer(snapshot: firebase.firestore.QuerySnapshot): QuestionAndAnswers {
  console.log('got qandas')
  const qandaList: QuestionAndAnswers = {}
  snapshot.forEach(docSnapshot => {
    const docData = docSnapshot.data()
    console.log('qanda data', docData)
    if (docData) {
      const qanda: QuestionAndAnswer = docData as QuestionAndAnswer
      qanda.question.createdAt = docData.question.createdAt ? docData.question.createdAt.toDate() : new Date()
      if (qanda.answer) {
        qanda.answer.createdAt = qanda.answer.createdAt ? docData.answer.createdAt.toDate() : new Date()
      }
      qandaList[docSnapshot.id] = qanda
    }
  })
  return qandaList
}

export function qandalSubscriber(ballotId: string): SubscriberReturnValue<Ballot> {
  return dbSubscriber('SET_QANDAS', qandaTransforer, ['props', ballotId, 'qandas'], null, 'question.createdAt', 'asc', null)
}
