import firebase from 'firebase/app'
import 'firebase/firestore'
import { auth } from './index'
import { SubscriberReturnValue, dbSubscriber, genericReducer } from './dbSubscriptions'

export type Vote = boolean | null
export interface UserVote {
  vote: Vote
  ballotId: string
}

function userVoteTransforer(snapshot: firebase.firestore.DocumentSnapshot): UserVote {
  let currentVote: Vote = null
  if (snapshot.exists) {
    const voteDoc = snapshot.data()
    if (voteDoc) {
      const value = voteDoc.vote
      if (value !== null && value !== undefined) currentVote = value
    }
  }
  return {
    vote: currentVote,
    ballotId: snapshot.ref.id,
  }
}

export function voteSubscriber(ballotId: string): SubscriberReturnValue<UserVote> {
  if (!auth.currentUser) {
    console.log('cannot subscribe to votes while not autenticated ' + ballotId)
    return {
      // tslint:disable-next-line: no-empty
      subscribe: () => {},
      // tslint:disable-next-line: no-empty
      unsubscribe: () => {},
    }
  }
  const dbPath = ['votes', auth.currentUser.uid, 'ballots', ballotId]
  return dbSubscriber('CURRENT_VOTE', userVoteTransforer, dbPath, null, null, null, null)
}

export const voteReducer = genericReducer<UserVote>('CURRENT_VOTE')
