import React from 'react'
import { State } from '../store/State'
import { Location } from 'history'
import { connect } from 'react-redux'
import ContentSqueezer from './ContentSqueezer'
import Typography from '@material-ui/core/Typography'

interface Props {
  location: Location
}

const NoMatch: React.FC<Props> = (props) => {
  if (process.env.NODE_ENV === 'development') {
    return (
      <div>
        <div>No Match {JSON.stringify(props.location)}</div>
        <div>No Match {JSON.stringify(window.location)}</div>
      </div>
    )
  }
  return (
    <ContentSqueezer>
      <Typography variant="h5" style={{ padding: 20 }}>
        Something's not right...
      </Typography>
      <img src='/puppy.jpeg' width="400px" height="257px" />
    </ContentSqueezer>
  )
}

function mapStateToProps(state: State) {
  return {
    location: state.router.location,
  }
}

export default connect(mapStateToProps)(NoMatch)
