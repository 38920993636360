import { SubscriberReturnValue, dbSubscriber, SetValueAction } from './dbSubscriptions'
import { Action } from 'redux'

interface UpdateSubscripionActionPayload {
  id: string
  value: boolean
}

// function subscriptionTransformer(snapshot: firebase.firestore.QuerySnapshot): UpdateSubscripionActionPayload[] {
//   return snapshot
//     .docChanges()
//     .filter(docChange => docChange.type !== 'modified')
//     .map(docChange => {
//       const id = docChange.doc.id
//       if (docChange.type === 'added') {
//         return { id, value: true }
//       } else {
//         return { id, value: true }
//       }
//     })
// }

function subscriptionTransformer(snapshot: firebase.firestore.DocumentSnapshot): UpdateSubscripionActionPayload {
  console.log('subscriptionTransformer', snapshot.exists)
  return { id: snapshot.id, value: snapshot.exists }
}

export function notificationSubscription(uid: string, id: string): SubscriberReturnValue<boolean> {
  return dbSubscriber('SUBSCRIPTION_UPDATE', subscriptionTransformer, ['users', uid, 'subscriptions', id], null, null, null, null)
}

export interface SubscriptionState {
  [id: string]: boolean | undefined
}

export function subscriptionReducer(state: SubscriptionState = {}, action: Action): SubscriptionState {
  if (action.type === 'SUBSCRIPTION_UPDATE') {
    const newState = { ...state }
    const change = (action as SetValueAction<UpdateSubscripionActionPayload>).value
    if (change.value) {
      newState[change.id] = true
    } else {
      delete newState[change.id]
    }
    return newState
  }
  return state
}
