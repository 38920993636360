import { Action, Dispatch, AnyAction } from 'redux'
import { subscribeToCollection, unsubscribeFromCollectionOrDocument, subscribeToDocument } from './index'
import 'firebase/app'
import 'firebase/firestore'

// import { store } from '../store'

export interface SetValueAction<T> extends Action {
  value: T
}

export type Subscriber<T> = () => SubscriberReturnValue<T>

export function genericReducer<T>(type: string) {
  return function reducer(state: T | null = null, action: Action): T | null {
    switch (action.type) {
      case type:
        return (action as SetValueAction<T>).value
      default:
        return state
    }
  }
}

export function dbSubscriber<T, SnapshotType>(
  actionType: string,
  transformer: (snapshot: SnapshotType) => T,
  dbPath: string[],
  where: string[] | null,
  orderBy: string | null,
  orderByDirection: 'asc' | 'desc' | null,
  limit: number | null,
) {
  console.log('dbSubscriber', dbPath, where, orderBy, orderByDirection)
  function createSetValueAction(value: T): SetValueAction<T> {
    return {
      type: actionType,
      value,
    }
  }

  function subscribe(dispatch: Dispatch<AnyAction>) {
    // console.log('subscribing to document', dbPath)
    if (dbPath.length % 2 === 0) {
      subscribeToDocument(
        snapshot => {
          const t = transformer((snapshot as unknown) as SnapshotType)
          dispatch(createSetValueAction(t))
        },
        dbPath,
        where,
      )
    } else {
      // console.log('subscribing to collection', dbPath)
      subscribeToCollection(
        snapshot => {
          const t = transformer((snapshot as unknown) as SnapshotType)
          dispatch(createSetValueAction(t))
        },
        dbPath,
        where,
        orderBy,
        orderByDirection,
        limit,
      )
    }
  }

  function unsubscribe() {
    unsubscribeFromCollectionOrDocument(dbPath, where)
  }
  return {
    subscribe,
    unsubscribe,
  }
}

export interface SubscriberReturnValue<T> {
  // reducer: (state: T | null | undefined, action: Action<any>) => T | null
  subscribe: (dispatch: Dispatch<AnyAction>) => void
  unsubscribe: () => void
}
