import { createBrowserHistory, createHashHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
// import { userCacheImpl } from '../firebase/UserCache'
import { preferencesPersistence } from '../model/Preferences'
import { createRootReducer } from './createRootReducer'
import { routerListener } from '../utils/routerListener'

export const history = window.location.protocol === 'file:' ? createHashHistory() : createBrowserHistory()

export function configureStore(preloadedState?: any) {
  const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(applyMiddleware(routerMiddleware(history), preferencesPersistence, routerListener)),
  )
  // userCacheImpl.setStore(store)

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./createRootReducer', () => {
        store.replaceReducer(createRootReducer(history))
      })
    }
  }

  return store
}

// export const store = configureStore()
