import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import RichTextEditor from '../components/rte/MUIRichTextEditor'
import User from '../model/AuthenticatedUser'
import { Typography } from '@material-ui/core'
import UserProfile from '../model/UserProfile'
import ContentSqueezer from '../components/ContentSqueezer'
import NotificationButton from '../components/NotificationButton'
import BallotListViewForUser from '../components/BallotListViewForUser'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
  },
  imageDiv: {
    position: 'relative',
    width: 300,
    height: 300,
    minHeight: 300,
    minWidth: 300,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
}))

interface ProfileViewProps {
  user: User
  userProfile: UserProfile
}

const ProfileView: React.FunctionComponent<ProfileViewProps> = props => {
  const classes = useStyles()
  const isCordova = window.location.protocol === 'file:'
  const prefix = isCordova ? '' : '/'
  const accountCircle = prefix + 'account-circle-300x300.png'
  const fullPhotoUrl = props.userProfile.fullPhotoUrl || accountCircle
  return (
    <React.Fragment>
      <ContentSqueezer>
        <div>
          <Typography component='span' variant='h5' style={{ flexGrow: 1 }}>
            {props.user.displayName}
          </Typography>
          <div style={{ float: 'right', clear: 'both' }}>
            <NotificationButton id={props.user.uid} />
          </div>
        </div>

        <div id='photo' className={classes.imageDiv} style={{ backgroundImage: `url(${fullPhotoUrl})` || 'none' }}></div>
        <RichTextEditor readOnly value={props.userProfile.bio} />
        <Typography>Ballots:</Typography>
        <BallotListViewForUser userId={props.user.uid} />
      </ContentSqueezer>
    </React.Fragment>
  )
}

export default ProfileView
