import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import SortIcon from '@material-ui/icons/Sort'
import Button from '@material-ui/core/Button'

import BallotQuery from '../model/BallotQuery'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { State } from '../store/State'
import { CurrentUser } from '../model/AuthenticatedUser'
import BallotListView from '../components/BallotListView'
import ContentSqueezer from '../components/ContentSqueezer'
import { parseUrlQuery, toUrlQuery } from '../utils/queryParameters'
import { push } from 'connected-react-router'

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 'bold',
    marginRight: 10,
  },
  select: {
    // minWidth: 220,
    marginLeft: 5,
    marginRight: 5,
    minWidth: 130,
  },
  ballotItem: {
    margin: 10,
    padding: 10,
  },
  spacer: {
    minHeight: 50,
    backgroundColor: 'purple',
  },
  query: {
    display: 'flex',
    justifyContent: 'space-evenly',
    left: 0,
    right: 0,
    top: 65,
    padding: 4,
    // height: 30,
    // minHeight: 30,
    marginBottom: 5,
    alignItems: 'center',
    // border: '1px dotted red',
    minHeight: 48,
  },
  querySearchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  queryResults: {
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    flexGrow: 1,
  },
  iconButton: {
    padding: 10,
  },
  plainLookingLinks: {
    textDecoration: 'none',
    color: 'inherit',
  },
  deemphasized: {
    fontSize: '0.85rem',
    color: '#666', // theme.typography.subtitle1.color,
    marginLeft: 5,
  },
}))

interface ExploreProps {
  ballotQuery: BallotQuery
  setBallotQuery: (ballotQuery: BallotQuery) => void
  user: CurrentUser
}

const Explore: React.FunctionComponent<ExploreProps> = props => {
  const classes = useStyles()
  const searchInputRef = React.createRef<HTMLInputElement>()
  const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null)
  const { ballotQuery } = props

  function renderEmptyBallotsForExplore() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 20 }}>
        <div style={{ margin: 10 }}>
          <Typography>No ballots found that match your search.</Typography>
        </div>
      </div>
    )
  }

  const search = () => {
    const searchTerm = searchInputRef.current ? searchInputRef.current.value : undefined
    console.log('search', searchTerm)
    const newBallotQuery: BallotQuery = {
      ...props.ballotQuery,
      searchText: searchTerm,
    }
    props.setBallotQuery(newBallotQuery)
  }

  const searchTextDefaultValue = props.ballotQuery ? { defaultValue: props.ballotQuery.searchText } : {}

  const onSortNewest = () => {
    handleCloseSort()
    console.log('onSortNewest')
    const newBallotQuery: BallotQuery = {
      ...props.ballotQuery,
      sortBy: 'createdAt',
      direction: 'desc',
    }
    props.setBallotQuery(newBallotQuery)
  }
  const onSortMostActive = () => {
    handleCloseSort()
    console.log('onSortMostActive')
    const newBallotQuery: BallotQuery = {
      ...props.ballotQuery,
      sortBy: 'totalVotes',
      direction: 'desc',
    }
    props.setBallotQuery(newBallotQuery)
  }
  const handleCloseSort = () => {
    setSortAnchorEl(null)
  }
  return (
    <ContentSqueezer>
      <Paper className={classes.query}>
        <InputBase
          id='ballotSearch'
          inputRef={searchInputRef}
          className={classes.querySearchInput}
          placeholder='Search Issues'
          inputProps={{ 'aria-label': 'search issues' }}
          onKeyDown={e => e.keyCode === 13 && search()}
          // defaultValue={props.ballotQuery ? props.ballotQuery.searchText : undefined}
          {...searchTextDefaultValue}
        />

        {/* <KeywordAutoComplete
          onValueChange={x => console.log(x)}
          id='ballotSearch'
          // inputRef={searchInputRef}
          className={classes.querySearchInput}
          placeholder='Search Issues'
          inputProps={{ 'aria-label': 'search issues' }}
          onKeyDown={e => e.keyCode === 13 && search()}
          {...searchTextDefaultValue}
        /> */}

        <IconButton className={classes.iconButton} aria-label='search' onClick={search}>
          <SearchIcon />
        </IconButton>
        <Button endIcon={<SortIcon />} onClick={e => setSortAnchorEl(e.currentTarget)}>
          Sort By
        </Button>
        <Menu anchorEl={sortAnchorEl} keepMounted open={Boolean(sortAnchorEl)} onClose={handleCloseSort}>
          <MenuItem onClick={onSortNewest}>Newest</MenuItem>
          <MenuItem onClick={onSortMostActive}>Most Active</MenuItem>
        </Menu>
      </Paper>
      <div id='queryResults' className={classes.queryResults}>
        <BallotListView ballotQuery={ballotQuery} renderEmptyList={renderEmptyBallotsForExplore} />
      </div>
    </ContentSqueezer>
  )
}

const mapStateToProps = (state: State) => {
  const searchParams = parseUrlQuery(state.router.location.search)
  const ballotQuery: BallotQuery = {
    sortBy: searchParams.sortby === 'mostActive' ? 'totalVotes' : 'createdAt',
    direction: 'desc',
    searchText: searchParams.keywords,
  }
  return {
    user: state.user,
    ballotQuery,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setBallotQuery: (ballotQuery: BallotQuery) => {
      const queryParams: any = {}
      if (ballotQuery.sortBy) {
        queryParams.sortby = ballotQuery.sortBy === 'totalVotes' ? 'mostActive' : 'newest'
      }
      if (ballotQuery.searchText) {
        queryParams.keywords = ballotQuery.searchText
      }
      dispatch(push({ search: toUrlQuery(queryParams) }))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Explore)
