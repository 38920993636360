import React from 'react'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import Link from '@material-ui/core/Link'
import ListItem from '@material-ui/core/ListItem'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { RouteData } from '../routes'
import AppBarUser from './AppBarUser'
import { State } from '../store/State'
import { CurrentUser, getAuthenticatedUser } from '../model/AuthenticatedUser'
import UnreadItemsIndicator from './UnreadItemsIndicator'
import { signOut } from '../firebase/auth'

const drawerWidth = 240
const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  appBarSpacer: {
    minHeight: 65,
    // backgroundColor: 'purple',
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
  menuItem: {
    // tslint:disable-next-line: object-literal-key-quotes
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

export interface ResponsiveDrawerMenuItem {
  displayText: string
  route: string
}

interface ResponsiveDrawerProps {
  menuItems: RouteData[]
  onMenuItemClicked: (id: string) => void
  currentUser: CurrentUser
  currentRoutePathName: string
}

const ResponsiveDrawer: React.FunctionComponent<ResponsiveDrawerProps> = props => {
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen)
  }
  function handleDrawerClose() {
    // console.log('handle drawer close')
    if (mobileOpen) setMobileOpen(false)
  }

  const userAuthenticated = !!getAuthenticatedUser(props.currentUser)

  const drawer = (
    <div id='main-menu' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <List>
        {props.menuItems
          .filter(menuItem => menuItem.displayText !== '')
          .filter(menuItem => menuItem.route !== '/my-ballots' || userAuthenticated)
          .map((menuItem, index) => (
            <Link to={menuItem.route} component={RouterLink} onClick={handleDrawerClose} className={classes.menuItem} key={menuItem.route}>
              <ListItem button>
                <Typography variant='body1'>
                  <span id={menuItem.id}>{menuItem.displayText}</span>
                </Typography>
              </ListItem>
            </Link>
          ))}
        {userAuthenticated === true && (
          <React.Fragment>
            <ListItem button>
              <Link to='/profile' component={RouterLink} onClick={handleDrawerClose} className={classes.menuItem} key='profile'>
                <Typography variant='body1'>
                  <span id='profile'>My Profile</span>
                </Typography>
              </Link>
            </ListItem>
            <ListItem button onClick={() => void signOut()}>
              <Typography variant='body1'>
                <span id='asdf'>Sign Out</span>
              </Typography>
            </ListItem>
          </React.Fragment>
        )}
      </List>
      <div style={{ position: 'fixed', bottom: 0, margin: 8, color: '#bbb', fontSize: '0.8em' }}>
        Build: {process.env.REACT_APP_GIT_HASH}
      </div>
    </div>
  )
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <IconButton
            id='hamburgerMenu'
            color='inherit'
            aria-label='Open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap style={{ flexGrow: 1 }}>
            Sayeso
          </Typography>
          <UnreadItemsIndicator />
          <AppBarUser />
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer} hidden={props.currentRoutePathName === '/signin'}>
        <Hidden smUp implementation='css'>
          <Drawer
            variant='temporary'
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
              <CloseIcon />
            </IconButton>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation='css'>
          <Drawer
            className={classes.drawer}
            variant='permanent'
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar} />
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <div className={classes.toolbar} />
      <div id='mainContent' className={classes.content}>
        <div id='appBarSpacer' className={classes.appBarSpacer} />
        {props.children}
      </div>
    </div>
  )
}

function mapStateToProps(state: State) {
  return {
    currentUser: state.user,
    currentRoutePathName: state.router.location.pathname,
  }
}

const x = connect(mapStateToProps)(ResponsiveDrawer)
export default connect(mapStateToProps)(ResponsiveDrawer)
