import { History } from 'history'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
// import createRootReducer from '../reducers'

import { counterReducer } from '../screens/ReduxExample'
import { ballotReducer, draftBallotReducer } from '../model/Ballot'
// import { userCacheReducer } from '../firebase/UserCache'
import { preferencesReducer } from '../model/Preferences'
import { userReducer } from '../model/AuthenticatedUser'
import { postAuthReducer } from '../firebase/auth'
import { voteReducer } from '../firebase/currentVoteForBallot'
import { ballotListReducer } from '../firebase/ballotList'
import { State } from './State'
import { subscriptionReducer } from '../firebase/notificationSubscriber'
import { qandasReducer } from '../model/QuestionAndAnswer'
import { unreadItemsReducer } from '../model/UnreadItems'

export const createRootReducer = (history: History) =>
  combineReducers<State>({
    count: counterReducer,
    currentBallot: ballotReducer,
    currentBallotQandAs: qandasReducer,
    draftBallot: draftBallotReducer,
    user: userReducer,
    // userCache: userCacheReducer,
    router: connectRouter(history),
    preferences: preferencesReducer,
    postAuth: postAuthReducer,
    userVote: voteReducer,
    ballotList: ballotListReducer,
    notificationSubscriptions: subscriptionReducer,
    unreadItems: unreadItemsReducer
  })
