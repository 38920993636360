import React from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import FirebaseUI, { getFirebaseUI } from '../components/FirebaseUI'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { State } from '../store/State'
import { connect } from 'react-redux'
interface LoginProps {
  firebaseAuth?: firebase.auth.Auth
  redirectUrl?: string
}

interface LoginState {
  loginUiFlowStarted: boolean
}

const signInSuccessWithAuthResult = (authResult: any, redirectUrl?: string): boolean => {
  console.log('signInSuccessWithAuthResult', authResult, redirectUrl)
  return false
}

const signInFailure = (error: firebaseui.auth.AuthUIError) => {
  console.log('signInFailure', error)
  return Promise.resolve()
}

const uiShown = () => {
  console.log('uiShown')
}

function isSmallScreen() {
  return window.innerHeight <= 750
}

export class SignIn extends React.Component<LoginProps, LoginState> {
  state = {
    loginUiFlowStarted: false,
  }

  uiConfig: firebaseui.auth.Config = {
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID, firebase.auth.EmailAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult,
      signInFailure,
      uiShown,
    },
    signInSuccessUrl: this.props.redirectUrl,
    credentialHelper: 'none',
  }

  render() {
    console.log('login render loginUiFlowStarted', this.state.loginUiFlowStarted, getFirebaseUI().isPendingRedirect())
    // const isSmall = isSmallScreen()
    return (
      <div>
        {/* {this.renderPrompt()} */}
        {/* {this.renderButton()} */}
        {this.renderFBUI()}
      </div>
    )
  }

  componentDidMount = () => {
    console.log('SignIn componentDidMount')
  }

  renderPrompt() {
    const renderPrompt = (!isSmallScreen() || !this.state.loginUiFlowStarted) && !getFirebaseUI().isPendingRedirect()
    if (!renderPrompt) {
      return undefined
    }
    return (
      <Container>
        <Typography align='center' variant='h5' style={{ marginTop: '1em' }}>
          Please log in to continue:
        </Typography>
      </Container>
    )
  }

  renderButton() {
    const renderButton = isSmallScreen() && !this.state.loginUiFlowStarted && !getFirebaseUI().isPendingRedirect()
    if (!renderButton) {
      return undefined
    }
    return (
      // <button className='pure-button pure-button-primary' onClick={this.onClickLogin} style={{ margin: '4em auto', display: 'block' }}>
      //   Login
      // </button>
      <div style={{ textAlign: 'center', paddingTop: '2em' }}>
        <Button variant='contained' color='primary' onClick={this.onClickLogin}>
          Log In
        </Button>
      </div>
    )
  }

  renderFBUI() {
    // const renderFBUI = !isSmallScreen() || this.state.loginUiFlowStarted || getFirebaseUI().isPendingRedirect()
    // if (!renderFBUI) {
    //   return undefined
    // }
    console.log('this.props.firebaseAuth', this.props.firebaseAuth, this.uiConfig)
    // return <div>{this.props.redirectUrl}</div> // <FirebaseUI firebaseAuth={this.props.firebaseAuth} uiConfig={this.uiConfig} />
    return <FirebaseUI firebaseAuth={this.props.firebaseAuth} uiConfig={this.uiConfig} />
  }

  onClickLogin = () => {
    console.log('onClickLogin')
    this.setState({ loginUiFlowStarted: true })
  }
}

const mapStateToProps = (state: State) => ({
  redirectUrl: '/explore',
})

export default connect(mapStateToProps)(SignIn)
