import React, { useState, useEffect } from 'react'
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import Button from '@material-ui/core/Button'
import { State } from '../store/State'
import { SubscriptionState, notificationSubscription } from '../firebase/notificationSubscriber'
import { CurrentUser, UNKNOWN_AUTH, getAuthenticatedUser } from '../model/AuthenticatedUser'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { subscribeToNotifications, unsubscribeFromNotifications } from '../firebase'
import { isMessagingSupported } from '../firebase/firebaseCloudMessaging'
import { messaging } from 'firebase'
import { useAlertDialog } from './AlertDialog'
import { authenticate } from '../firebase/auth'
import { push } from 'connected-react-router'

interface NotificationButtonProps {
  id: string
  subscriptions: SubscriptionState
  currentUser: CurrentUser
  subscribeToState: (uid: string, id: string) => void
  unsubscribeFromState: (uid: string, id: string) => void
  authenticate: (postAuthActions: Action[]) => void
  createNavToCurrentLocationAction: () => Action
}

interface NotificationButtonState {
  didSubscribe: boolean
}

const NotificationButton: React.FC<NotificationButtonProps> = props => {
  // class NotificationButton extends React.Component<NotificationButtonProps, NotificationButtonState> {
  // constructor(props: NotificationButtonProps) {
  //   super(props)
  // }

  // state = {
  //   didSubscribe: false,
  // }
  const [didSubscribe, setDidSubscribe] = useState(false)
  const { subscriptions, id } = props
  const isSubscribed = subscriptions[id] === true
  const dialog = useAlertDialog()

  useEffect(() => {
    if (!didSubscribe) {
      startFollowStateSubscription()
      return () => {
        stopFollowingStateSubscription()
      }
    }
  }, [])

  // componentDidMount = () => this.startFollowStateSubscription()

  // componentDidUpdate = () => this.startFollowStateSubscription()

  function startFollowStateSubscription() {
    console.log(`subbing to follow state; isMessagingSupported: ${isMessagingSupported()}`)
    if (!isMessagingSupported()) {
      return
    }
    const u = getAuthenticatedUser(props.currentUser)
    if (u && !didSubscribe) {
      props.subscribeToState(u.uid, props.id)
      setDidSubscribe(true)
    }
  }

  function stopFollowingStateSubscription() {
    if (didSubscribe) {
      const u = getAuthenticatedUser(props.currentUser)
      // TODO possible to sign out before unmounting
      if (u) {
        props.unsubscribeFromState(u.uid, props.id)
      }
    }
  }

  function toggleSubscription() {
    // console.log(`entering toggleSubscription; isSubscribed=${isSubscribed}`)
    if (isSubscribed) {
      void unsubscribeFromNotifications(id)
    } else {
      // console.log(`!getAuthenticatedUser ${!getAuthenticatedUser(props.currentUser)}`)
      // console.log('zzzzzzzzzz')
      const u = getAuthenticatedUser(props.currentUser)
      // console.log(`got u`, u)

      if (u === null) {
        console.log('user not logged in while trying to subscribe')
        void dialog
          .alert({
            message: 'You must sign in before following a user.',
            cancelButton: true,
          })
          .then(result => {
            if (result) {
              props.authenticate([props.createNavToCurrentLocationAction()])
            }
          })
      } else {
        console.log('subbing')
        void subscribeToNotifications(id)
      }
    }
    console.log('leaving toggleSubscription')
  }

  if (props.currentUser === UNKNOWN_AUTH || !isMessagingSupported()) {
    return null
  }

  return (
    <Button color='primary' style={{ fontSize: '0.80rem' }} onClick={toggleSubscription}>
      {isSubscribed ? 'Following' : 'Follow'}
      <span style={{ minWidth: 5 }} />
      {isSubscribed ? <NotificationsActiveIcon /> : <NotificationsNoneOutlinedIcon />}
    </Button>
  )
}

const mapStateToProps = (state: State) => {
  return {
    subscriptions: state.notificationSubscriptions,
    currentUser: state.user,
    createNavToCurrentLocationAction: () => push(state.router.location),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    subscribeToState: (uid: string, id: string) => notificationSubscription(uid, id).subscribe(dispatch),
    unsubscribeFromState: (uid: string, id: string) => notificationSubscription(uid, id).unsubscribe(),
    authenticate: (postAuthActions: Action[]) => authenticate(dispatch, postAuthActions),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationButton)
