import { SubscriberReturnValue, dbSubscriber, genericReducer } from './dbSubscriptions'

import Ballot from '../model/Ballot'
import BallotQuery from '../model/BallotQuery'
import { toKeywords } from '../utils/toKeywords'

///////////////////////////////////////////////////
const BALLOT_LIMIT = 100
export interface BallotList {
  [ballotId: string]: Ballot
}

function ballotListTransformer(querySnapshot: firebase.firestore.QuerySnapshot) {
  const ballotList: BallotList = {}
  querySnapshot.forEach(snapshot => {
    // console.log('    > prop docSnapshot', snapshot.id)
    const docData = snapshot.data()
    if (docData) {
      ballotList[snapshot.id] = {
        id: snapshot.id,
        slug: docData.slug,
        headline: docData.headline,
        category: docData.category,
        description: docData.description,
        authorId: docData.authorId,
        authorName: docData.authorName,
        authorPhotoUrl: docData.authorPhotoUrl,
        authorSlug: docData.authorSlug,
        authorEmail: docData.authorEmail,
        draft: docData.draft,
        createdAt: docData.createdAt.toDate(),
        updatedAt: docData.updatedAt.toDate(),
        yays: docData.yays,
        nays: docData.nays,
        totalVotes: docData.totalVotes,
        googlePlace: docData.googlePlace,
      }
    }
  })
  return ballotList
}

export function ballotListSubscriber(ballotQuery: BallotQuery): SubscriberReturnValue<BallotList> {
  // console.log('ballotListSubscriber', ballotQuery)
  const where: string[] = []
  // if (ballotQuery.searchText && ballotQuery.searchText.length > 0) {
  //   const keywords = toKeywords(ballotQuery.searchText)
  //   for (const keyword of keywords) {
  //     where.push('keywords', 'array-contains', keyword)
  //   }
  // }

  if (ballotQuery.author) {
    where.push('author', '==', ballotQuery.author)
  }

  return dbSubscriber(
    'PROPOSAL_LIST',
    ballotListTransformer,
    ['props'],
    where,
    ballotQuery.sortBy || null,
    ballotQuery.direction || null,
    BALLOT_LIMIT,
  )
}

export const ballotListReducer = genericReducer<BallotList>('PROPOSAL_LIST')
