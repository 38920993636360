import { AnyAction } from 'redux'

export default interface User {
  uid: string
  email: string | null
  displayName: string | null
  photoUrl: string | null
  fcmTokens?: string[] | null
}

export const UNKNOWN_AUTH = 'unknown'

// undefined means we don't know yet
// null means definitely not authenticatd
export type CurrentUser = User | null | typeof UNKNOWN_AUTH

export function getAuthenticatedUser(user: CurrentUser): User | null {
  if (user === null || user === UNKNOWN_AUTH) {
    return null
  }
  return user
}

export function getUidOrNull(user: CurrentUser) {
  const authenticatedUser = getAuthenticatedUser(user)
  if (authenticatedUser === null) {
    return null
  }
  return authenticatedUser.uid
}

const USER_ACTION = 'AUTHENTICATED_USER'

interface UserAction extends AnyAction {
  user: CurrentUser
}

export const createUserAuthAction = (user: CurrentUser): UserAction => ({
  type: USER_ACTION,
  user,
})

export function userReducer(user: CurrentUser = UNKNOWN_AUTH, action: UserAction) {
  if (action.type === USER_ACTION) {
    return action.user
  }
  return user
}
