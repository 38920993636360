import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import { registerCleanupTask, unregisterCleanupTask } from '../utils/cleanupManager'
import User, { CurrentUser } from '../model/AuthenticatedUser'
import UserProfile from '../model/UserProfile'
import Ballot, { getDescriptionText } from '../model/Ballot'
import { Preferences, initialPreferences } from '../model/Preferences'
import { resizeDataUrl } from '../utils/resizeImage'
// import { userCacheImpl } from './UserCache'
import { toKeyword, toKeywords } from '../utils/toKeywords'

export type WhereFilterOp = '<' | '<=' | '==' | '>=' | '>' | 'array-contains'

// interface Config {
//   firebase: FirebaseOptions,
//   vapid: string
// }

type SayesoEnvironments = 'test' | 'staging'

const firebaseConfigs = {
  test: {
    apiKey: 'AIzaSyDfSkCVhfIu7N4l-RWYV7PlFrMRhg82OgM',
    authDomain: 'sayeso-test.firebaseapp.com',
    databaseURL: 'https://sayeso-test.firebaseio.com',
    projectId: 'sayeso-test',
    storageBucket: 'sayeso-test.appspot.com',
    messagingSenderId: '1073368836327',
    appId: '1:1073368836327:web:97d90a243c4283e213da42',
  },
  staging: {
    apiKey: 'AIzaSyAAOzx2z0SXGbZX8bpIpyEan11U1qCwyNg',
    authDomain: 'sayeso-staging.firebaseapp.com',
    databaseURL: 'https://sayeso-staging.firebaseio.com',
    projectId: 'sayeso-staging',
    storageBucket: 'sayeso-staging.appspot.com',
    messagingSenderId: '245178034200',
    appId: '1:245178034200:web:1b93bf066bd732ef',
  },
  live: {
    apiKey: 'AIzaSyA-xNLOl5yXK4akwxFmVRpeRx92lGBkNLk',
    authDomain: 'sayeso.com',
    databaseURL: 'https://sayeso-live.firebaseio.com',
    projectId: 'sayeso-live',
    storageBucket: 'sayeso-live.appspot.com',
    messagingSenderId: '377184453349',
    appId: '1:377184453349:web:1486ecf157a4c97bd90a0c',
  },
}

const publicVapidKeys = {
  test: 'BGRcNUq8KIcKpJBIAimoVvHNnhul0ztJj7DQMuEFtR7tZLzvtRfhWoysZx_2BV5_y8R0xtunIXyW3FeTGoUUE-Y',
  staging: 'BKOfhvEeXZ8ughiDqAp_qgRz24rTSQd3yNnAk-euwQrWjGE3kNaJ8Lm6wIqNY77zrM8nA4X6MBMmhOUDDLuqrwY',
  live: 'BBd1vV9xMBfmeWgAmOXj09Kz9XSiRRghxMm23m4B8QbKGJy45fu4v1DgqTH-K18tOXpTdU0xT9LBuAd_L0-nyUg',
}

let config: any
export let publicVapidKey: string = ''

if (process.env.REACT_APP_SAYESO_ENV && process.env.REACT_APP_SAYESO_ENV !== '') {
  const envKey = process.env.REACT_APP_SAYESO_ENV as SayesoEnvironments
  console.log('configuring firebase from REACT_APP_SAYESO_ENV', envKey)
  config = firebaseConfigs[envKey]
  publicVapidKey = publicVapidKeys[envKey]
} else {
  const host: string = window.location.hostname
  const isDev = host.includes('192.168') || host === 'localhost'
  const isCordova = window.location.protocol === 'file:'

  if (host.includes('test')) {
    console.log('configuring firebase for test')
    config = firebaseConfigs.test
    publicVapidKey = publicVapidKeys.test
  } else if (isDev || isCordova || host.includes('staging')) {
    // TODO: eventually we need 2 cordova build flavors,staging and prod
    console.log('configuring firebase for staging')
    config = firebaseConfigs.staging
    publicVapidKey = publicVapidKeys.staging
  } else {
    throw new Error('cannot initialize firebase, unknown host ' + host)
  }
}

export const firebaseApp = firebase.initializeApp(config)
// if (process.env.NODE_ENV === 'development') {
//   firebase.functions().useFunctionsEmulator('http://localhost:4001')
// }
console.log('firebase initialized')

export const auth = firebase.auth()

export const db = firebase.firestore()
void db.enablePersistence({ synchronizeTabs: true })

function onFirebaseError(error: Error) {
  console.error('firestore error', error)
}

let streamingUsers = false
export type QuerySnapshot = firebase.firestore.QuerySnapshot
export type CollectionCallback = (snapshot: QuerySnapshot) => void
export type DocumentCallback = (data: firebase.firestore.DocumentSnapshot) => void

const streamingFlags = new Map<string, boolean>()

// TODO this won't scale to thousands of users
export function streamUsers(callback: CollectionCallback): void {
  if (!streamingUsers) {
    streamingUsers = true
    console.log('opening firestore user stream')
    const users = db.collection('users')
    registerCleanupTask(users.onSnapshot(callback, onFirebaseError))
  } else {
    console.error('alerady streaming users')
  }
}

export function streamCollection(collectionName: string, callback: CollectionCallback): void {
  console.log('streamCollection', collectionName)
  const alreadyStreaming = streamingFlags.get(collectionName)
  if (alreadyStreaming) {
    console.error('alerady streaming collection', collectionName)
  } else {
    streamingFlags.set(collectionName, true)
    console.log(`opening firestore stream for ${collectionName}`)
    const collection = db.collection(collectionName)
    registerCleanupTask(collection.onSnapshot(callback, onFirebaseError))
  }
}

export async function putUser(user: User) {
  console.log('putting user')
  const { uid, ...rest } = user
  if (!rest.fcmTokens) {
    rest.fcmTokens = null
  }
  const putUserFunction = firebase.functions().httpsCallable('putUser')

  await putUserFunction(rest)
}

export async function getUser(uid: string): Promise<User | null> {
  console.log('getting user', uid)
  const docData = await getDocumentWithId('users', uid)
  if (!docData) {
    return null
  }
  return {
    uid,
    email: docData.email,
    displayName: docData.displayName,
    photoUrl: docData.photoUrl,
    fcmTokens: docData.fcmTokens,
  }
}
export async function getUserBySlug(slug: string): Promise<User | null> {
  console.log('getting user by slug', slug)
  const querySnapshot = await db
    .collection('users')
    .where('slug', '==', slug)
    .get()
  if (querySnapshot.empty) {
    return null
  }
  const docData = querySnapshot.docs[0].data()
  return {
    uid: querySnapshot.docs[0].id,
    email: docData.email,
    displayName: docData.displayName,
    photoUrl: docData.photoUrl,
    fcmTokens: docData.fcmTokens,
  }
}

export async function putUserProfile(uid: string, up: UserProfile) {
  console.log('putting user profile')
  return putDocumentWithId('profiles', uid, up)
}

export async function getUserProfile(uid: string): Promise<UserProfile | null> {
  console.log('getting full user', uid)
  const docData = await getDocumentWithId('profiles', uid)
  if (!docData) {
    return null
  }
  return {
    fullPhotoUrl: docData.fullPhotoUrl,
    bio: docData.bio,
  }
}
export async function getUserProfileBySlug(slug: string): Promise<UserProfile | null> {
  console.log('getting user profile by slug', slug)
  const querySnapshot = await db
    .collection('profiles')
    .where('slug', '==', slug)
    .get()
  if (querySnapshot.empty) {
    return null
  }
  const docData = querySnapshot.docs[0].data()
  return {
    fullPhotoUrl: docData.fullPhotoUrl,
    bio: docData.bio,
  }
}

export async function putBallot(ballot: Ballot, author: CurrentUser) {
  console.log('putting ballot', ballot)

  if (author === null || author === 'unknown') {
    // caller should call this unless there is an authenticated user
    throw new Error('user is not authenticated')
  }

  const doc: any = ballot
  // doc.authorInfo = { ...author }
  // delete doc.authorInfo.fcmTokens
  // const keywords: string[] = []

  // console.log('ballot.author', ballot.author)
  // if (doc.authorId) {
  //   // const authorUser = await userCacheImpl.fetchUser(doc.author)

  //   // console.log('authorUser', authorUser)
  //   if (author.displayName) {
  //     keywords.push(...toKeywords(author.displayName))
  //   }
  // }
  // keywords.push(...toKeywords(ballot.headline))
  // const loc = ballot.location
  // if (loc) {
  //   keywords.push(toKeyword(loc.mainTerm))
  // }
  // keywords.push(toKeyword(ballot.category))
  // doc.keywords = keywords
  doc.descriptionText = getDescriptionText(ballot)

  const putBallotFunction = firebase.functions().httpsCallable('putBallot')
  const result = await putBallotFunction(doc)
  console.log('put ballot result', result)
  return result.data
}

export const markBallotRead = firebase.functions().httpsCallable('markBallotRead')

async function putDocumentWithId(collection: string, id: string, document: any) {
  console.log('putting document', collection, id, document)
  try {
    await db
      .collection(collection)
      .doc(id)
      .set(document)
    console.log('put document')
    return true
  } catch (e) {
    console.error('caught while putting document', e)
    return false
  }
}

async function getDocumentWithId(collection: string, id: string) {
  console.log('getting document', collection, id, document)
  const result = await db
    .collection(collection)
    .doc(id)
    .get()
  console.log('getDoc query complete, exists:', result.exists)
  return result.data()
}

// async function putDocument(collection: string, document: any) {
//   console.log('putting document', collection, document)
//   try {
//     const docRef = await db.collection(collection).add(document)
//     console.log('put document without id', docRef.id)
//     return docRef.id
//   } catch (e) {
//     console.error('caught while putting document', e)
//     return null
//   }
// }

export async function putPreferences(preferences: Preferences) {
  if (auth.currentUser === null) {
    console.log('cannot persist preferences until user has authenticated')
    return
  }
  const uid = auth.currentUser.uid
  console.log('persistPreferences', preferences, uid)
  void putDocumentWithId('preferences', uid, preferences)
}
export async function getPreferences(): Promise<Preferences> {
  if (auth.currentUser === null) {
    return initialPreferences
  }
  const prefs = await getDocumentWithId('preferences', auth.currentUser.uid)
  if (!prefs) {
    return initialPreferences
  }
  return prefs as Preferences
}

// streamUsers(onDbUser)

const streamingUnregisters = new Map<string, () => void>()

function createKey(dbPath: string[], where?: string[] | null) {
  const key = dbPath.join('/')
  if (where && where.length > 0) {
    return key + ' ' + where.join(' ')
  }
  return key
}

export function subscribeToDocument(callback: DocumentCallback, dbPath: string[], where?: string[] | null): void {
  // console.log('subscribing to document', dbPath)
  validateDbPathForDocument(dbPath)
  const key = createKey(dbPath, where)
  let collectionRef = db.collection(dbPath[0])
  let docRef = collectionRef.doc(dbPath[1])
  for (let i = 2; i < dbPath.length; i += 2) {
    collectionRef = docRef.collection(dbPath[i])
    docRef = collectionRef.doc(dbPath[i + 1])
  }
  const unSub = docRef.onSnapshot(callback)
  registerCleanupTask(unSub)
  streamingUnregisters.set(key, unSub)
}

export function subscribeToCollection(
  callback: CollectionCallback,
  dbPath: string[],
  where: string[] | null,
  orderBy: string | null,
  orderByDirection: 'asc' | 'desc' | null,
  limit: number | null,
): void {
  // console.log('subscribing to collection', dbPath)
  validateDbPathForCollection(dbPath)
  const key = createKey(dbPath, where)
  const existingUnsub = streamingUnregisters.get(key)
  if (existingUnsub) {
    console.log('already subscribed!', dbPath)
    return
  }
  let collectionRef = db.collection(dbPath[0])
  for (let i = 1; i < dbPath.length; i += 2) {
    const docRef = collectionRef.doc(dbPath[i])
    collectionRef = docRef.collection(dbPath[i + 1])
  }
  let q: firebase.firestore.Query = collectionRef

  // tslint:disable-next-line: no-empty
  let unSub = () => {}
  // TODO only handles simple queries
  if (where && where.length > 0) {
    if (where.length % 3 !== 0) {
      throw new Error(`cannot have where clause with ${where.length} elements`)
    }
    for (let i = 0; i < where.length; i += 3) {
      q = q.where(where[i], where[i + 1] as WhereFilterOp, where[i + 2])
    }
  }
  if (orderBy) {
    if (orderByDirection) {
      q = q.orderBy(orderBy, orderByDirection)
    } else {
      q = q.orderBy(orderBy)
    }
  }
  if (limit) {
    q = q.limit(limit)
  }

  unSub = q.onSnapshot(callback)

  registerCleanupTask(unSub)
  streamingUnregisters.set(key, unSub)
}

export function unsubscribeFromCollectionOrDocument(dbPath: string[], where?: string[] | null): void {
  const key = createKey(dbPath, where)
  const unSub = streamingUnregisters.get(key)
  console.log('unsubscribing', dbPath, key, unSub)
  // validateDbPathForCollection(dbPath)
  if (unSub) {
    unregisterCleanupTask(unSub)
    streamingUnregisters.delete(key)
    unSub()
  } else {
    console.log('unsubscribe not found!', dbPath)
  }
}

function validateDbPathForDocument(dbPath: string[]) {
  const key = dbPath.join('/')
  if (dbPath.length < 2) {
    throw new Error(`dbPath must have at least 2 elements: ${key}`)
  }
  if (dbPath.length % 2 !== 0) {
    throw new Error(`dbPath must have an even number of elements: %{key}`)
  }
}
function validateDbPathForCollection(dbPath: string[]) {
  const key = dbPath.join('/')
  if (dbPath.length < 1) {
    throw new Error(`dbPath must have at least 1 elements: ${key}`)
  }
  if (dbPath.length % 2 !== 1) {
    throw new Error(`dbPath must have an odd number of elements: %{key}`)
  }
}

export function setVote(ballotId: string, vote: boolean | null) {
  if (!auth.currentUser) {
    console.log('unauthenticated users cannot vote')
    return false
  }
  console.log('recording vote', ballotId, vote)
  const docRef = db
    .collection('votes')
    .doc(auth.currentUser.uid)
    .collection('ballots')
    .doc(ballotId)
  if (vote === null) {
    void docRef.delete()
  } else {
    void docRef.set({ vote })
  }
  return true
}

export async function saveUserPhotos(originalDataUrl: string, newPhotoWidth: number, newPhotoHeight: number) {
  const user = auth.currentUser
  if (!user) {
    console.error("can't save user photos while unauthenticated")
    return [] as string[]
  }

  const fullDataUrl = await resizeDataUrl(originalDataUrl, newPhotoWidth, newPhotoHeight, 300, 300)

  console.log('got data url', fullDataUrl.length)
  const smallDataUrl = await resizeDataUrl(fullDataUrl, newPhotoWidth, newPhotoHeight, 96, 96)

  const userDir = firebase.storage().ref(`/userPhotos/${user.uid}`)
  const fullSaveResultPromise = saveDataUrl(fullDataUrl, userDir.child('full.jpg'))
  const smallSaveResultPromise = saveDataUrl(smallDataUrl, userDir.child('small.jpg'))
  const fullResult = await fullSaveResultPromise
  const smallResult = await smallSaveResultPromise
  const fullUrl = await fullResult.ref.getDownloadURL()
  const smallUrl = await smallResult.ref.getDownloadURL()
  console.log('full', fullUrl)
  console.log('small', smallUrl)
  return [fullUrl as string, smallUrl as string]
}

async function saveDataUrl(dataUrl: string, ref: firebase.storage.Reference) {
  const fetchResult = await fetch(dataUrl)
  const blob = await fetchResult.blob()
  const putResult = ref.put(blob)
  return putResult
}

export async function subscribeToNotifications(id: string) {
  const user = auth.currentUser
  if (!user) {
    console.log("can't subscribe while unauthenticated")
    return
  }
  console.log('subscribing to ', id)
  void db
    .collection('users')
    .doc(user.uid)
    .collection('subscriptions')
    .doc(id)
    .set({})
}

function assertAuthenticated(errorMessage: string) {
  const user = auth.currentUser
  if (!user) {
    throw new Error(errorMessage)
  }
}

export async function unsubscribeFromNotifications(id: string) {
  assertAuthenticated("can't subscribe while unauthenticated")
  const user = auth.currentUser
  void db
    .collection('users')
    .doc(user!.uid)
    .collection('subscriptions')
    .doc(id)
    .delete()
}

export async function addQuestion(ballotId: string, content: string, user: User) {
  assertAuthenticated("can't ask question while unauthenticated")
  // const user = auth.currentUser
  const doc = {
    question: {
      askerId: user.uid,
      askerDisplayName: user.displayName,
      askerPhotoUrl: user.photoUrl,
      content,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    },
  }

  const result = await db
    .collection('props')
    .doc(ballotId)
    .collection('qandas')
    .add(doc)

  console.log('add question result', result)
}

export async function setAnswer(ballotId: string, questionId: string, content: string) {
  assertAuthenticated("can't ask question while unauthenticated")
  // const user = auth.currentUser
  const doc = {
    answer: {
      content,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    },
  }

  console.log('setting answer', ballotId, questionId)

  const result = await db
    .collection('props')
    .doc(ballotId)
    .collection('qandas')
    .doc(questionId)
    .update(doc)

  console.log('set answer result', result)
}
