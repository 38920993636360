import { Action } from 'redux'
import { SetValueAction } from '../firebase/dbSubscriptions'
import User from './AuthenticatedUser'

export interface Question {
  askerId: string
  askerDisplayName: string
  askerPhotoUrl: string
  askerSlug: string
  content: string
  createdAt: Date
}

export interface Answer {
  content: string
  createdAt: Date
}

export interface QuestionAndAnswer {
  question: Question
  answer: Answer | null | undefined
}

export interface QuestionAndAnswers {
  [qandaId: string]: QuestionAndAnswer
}

export function qandasReducer(state: QuestionAndAnswers = {}, action: Action) {
  switch (action.type) {
    case 'SET_QANDAS':
      return (action as SetValueAction<QuestionAndAnswers>).value
    default:
      return state
  }
}
