import React from 'react'
import CheckBoxOutlineBlankRoundedIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

export const EducationIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d='M12 3L1 9l11 6 9-4.91V17h2V9M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82z' />
  </SvgIcon>
)

export const EnvironmentIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d='M17 8C8 10 5.9 16.17 3.82 21.34l1.89.66.95-2.3c.48.17.98.3 1.34.3C19 20 22 3 22 3c-1 2-8 2.25-13 3.25S2 11.5 2 13.5s1.75 3.75 1.75 3.75C7 8 17 8 17 8z' />
  </SvgIcon>
)
export const EthicsIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d='M12 3c-1.27 0-2.4.8-2.82 2H3v2h1.95L2 14c-.47 2 1 3 3.5 3s4.06-1 3.5-3L6.05 7h3.12c.33.85.98 1.5 1.83 1.83V20H2v2h20v-2h-9V8.82c.85-.32 1.5-.97 1.82-1.82h3.13L15 14c-.47 2 1 3 3.5 3s4.06-1 3.5-3l-2.95-7H21V5h-6.17C14.4 3.8 13.27 3 12 3m0 2a1 1 0 011 1 1 1 0 01-1 1 1 1 0 01-1-1 1 1 0 011-1m-6.5 5.25L7 14H4l1.5-3.75m13 0L20 14h-3l1.5-3.75z' />
  </SvgIcon>
)
export const FinanceIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d='M6 16.5l-3 2.94V11h3m5 3.66l-1.57-1.34L8 14.64V7h3m5 6l-3 3V3h3m2.81 9.81L17 11h5v5l-1.79-1.79L13 21.36l-3.47-3.02L5.75 22H3l6.47-6.34L13 18.64' />
  </SvgIcon>
)
export const HumanRightsIcon = (props: SvgIconProps) => (
  // <svg viewBox='0 0 1520 1520' {...props}>
  <SvgIcon {...props}>
    <path d='M4.1 5.4h15.7V11H4.1zM4.1 13.2h15.7v5.6H4.1z' />
  </SvgIcon>
)
export const HealthCareIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d='M13 4h-2l-1-2h4l-1 2m1 4V6h1V5H9v1h1v2c-2.76 0-5 2.24-5 5v9h14v-9c0-2.76-2.24-5-5-5m2 9h-3v3h-2v-3H8v-2h3v-3h2v3h3v2z' />
  </SvgIcon>
)
export const ImmigraitonIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d='M6 2a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2H6m6 3a5 5 0 015 5 5 5 0 01-5 5 5 5 0 01-5-5 5 5 0 015-5m0 1c-.41.62-.75 1.29-.96 2h1.92A7.23 7.23 0 0012 6m-1.3.22C9.78 6.53 9 7.17 8.54 8H10c.18-.62.4-1.22.7-1.78m2.59 0c.3.56.53 1.16.71 1.78h1.46c-.46-.83-1.25-1.46-2.17-1.78M8.13 9c-.08.32-.13.65-.13 1s.05.68.13 1h1.69c-.04-.33-.07-.66-.07-1 0-.34.03-.67.07-1H8.13m2.7 0c-.05.32-.08.66-.08 1 0 .34.03.67.08 1h2.34c.04-.33.08-.66.08-1 0-.34-.04-.68-.08-1h-2.34m3.35 0c.04.33.07.66.07 1 0 .34-.03.67-.07 1h1.69c.08-.32.13-.65.13-1s-.05-.68-.13-1h-1.69m-5.64 3c.46.83 1.24 1.46 2.16 1.78-.3-.56-.52-1.15-.7-1.78H8.54m2.5 0c.21.72.55 1.38.96 2 .42-.62.75-1.28.96-2h-1.92M14 12c-.18.63-.41 1.22-.71 1.78.92-.32 1.71-.95 2.17-1.78H14m-7 5h10v2H7v-2z' />
  </SvgIcon>
)
export const InfrastructureIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d='M7 14v-3.09c-.72-.33-1.39-.73-2-1.2V14h2m-2 4H3v-2H1v-2h2V7h2v1.43C6.8 10 9.27 11 12 11c2.73 0 5.2-1 7-2.57V7h2v7h2v2h-2v2h-2v-2H5v2m12-7.09V14h2V9.71c-.61.47-1.28.87-2 1.2M16 14v-2.68c-.64.23-1.31.4-2 .52V14h2m-3 0v-2.04L12 12l-1-.04V14h2m-3 0v-2.16c-.69-.12-1.36-.29-2-.52V14h2z' />
  </SvgIcon>
)

export const PovertyIcon = (props: SvgIconProps) => (
  // <svg viewBox='0 0 13.8 13.8' {...props}>
  <SvgIcon {...props}>
    <path d='M4.723 19.159a9.406 9.406 0 00-3.194.485.626.626 0 00-.396.792c.002.003.002.009.003.014a4.383 4.383 0 002.798 2.697c.379.121.785.121 1.164 0a4.382 4.382 0 002.796-2.697.624.624 0 00-.379-.8c-.003-.002-.009-.002-.012-.003a9.118 9.118 0 00-2.78-.488zm-.005.436a9.015 9.015 0 012.656.462.19.19 0 01.118.239.197.197 0 01-.118.118 9.03 9.03 0 01-5.703 0 .19.19 0 01-.118-.239.197.197 0 01.118-.118 9.035 9.035 0 013.047-.462zM18.744 4.998a4.333 4.333 0 10-8.667-.051v.051a4.333 4.333 0 108.667.051v-.051zM13.888 15.504c0-1.201-.972-2.173-2.173-2.173s-2.173.972-2.173 2.173v5.683c0 1.201.972 2.173 2.173 2.173s2.173-.972 2.173-2.173v-5.683zM14.929 21.187a2.174 2.174 0 004.345 0v-5.683a2.174 2.174 0 00-4.345 0v5.683z' />
    <path d='M7.494 8.424a19.953 19.953 0 00-.473 4.424 1.884 1.884 0 002.078 1.653 2.802 2.802 0 014.236-1.277c.191-.328.396-.645.616-.954a8.983 8.983 0 00-4.412-.282 17.34 17.34 0 01.409-3.079l.624.073c-.172.747-.293 1.51-.36 2.276a9.55 9.55 0 014.188.503 9.567 9.567 0 014.188-.503 16.816 16.816 0 00-.36-2.276l.624-.073a17.17 17.17 0 01.408 3.079 8.95 8.95 0 00-3.579.055 2.518 2.518 0 00-1.576 1.102c-.106.165-.206.336-.302.505.278.314.481.688.594 1.092a2.8 2.8 0 015.306-.244 1.882 1.882 0 002.078-1.653 19.873 19.873 0 00-.885-5.981 2.93 2.93 0 00-1.547-1.738 4.956 4.956 0 01-5.089 4.821 4.953 4.953 0 01-4.821-4.821 2.942 2.942 0 00-1.544 1.738c-.162.524-.3 1.053-.418 1.588' />{' '}
  </SvgIcon>
)

export const SocialIssuesIcon = (props: SvgIconProps) => (
  // <svg viewBox='0 0 28.98 28.29' {...props}>
  <SvgIcon {...props}>
    <path d='M7.86 16.456c.334-.397.57-.866.689-1.371a5.96 5.96 0 01-.792-2.298 4.429 4.429 0 01-.063-.65 4.411 4.411 0 010-.46L6.561 9.744l3.313-1.878-2.426-4.09-7.132 4.05 2.377 4.081 3.336-1.862 1.308 2.227a2.41 2.41 0 00-1.22-.325 2.504 2.504 0 00-2.52 2.48 3.294 3.294 0 001.395 2.591c-1.974.492-3.432 2.18-3.432 3.424 0 .571 1.331.919 2.98 1.046v-.079c-.016-1.775 1.371-3.693 3.32-4.953zm-4.913-5.349L1.156 8.064 7.25 4.601l1.783 3.043-6.086 3.463zm13.092-1.989c.382.44.678.948.872 1.498V6.574h5.024V.694H11.006v5.88h5.032v2.544zm3.812 7.925a3.297 3.297 0 001.395-2.591 2.511 2.511 0 00-4.09-1.925 5.94 5.94 0 01-.864 2.583c.119.493.346.953.666 1.347 1.95 1.26 3.336 3.17 3.336 4.953v.079c1.648-.127 2.988-.475 2.988-1.046.016-1.244-1.458-2.932-3.431-3.424v.024zm-5.698-.888a5.05 5.05 0 002.148-3.962 3.884 3.884 0 00-7.759 0 5.06 5.06 0 002.148 3.962c-3.043.792-5.294 3.352-5.294 5.278 0 2.274 14.051 2.274 14.051 0 0-1.95-2.251-4.541-5.294-5.302v.024z' />{' '}
  </SvgIcon>
)

interface Category {
  key: string
  displayText: string
  icon: React.ComponentType<SvgIconProps>
}

export const categories: Category[] = [
  {
    key: 'education',
    displayText: 'Education',
    icon: EducationIcon,
  },
  {
    key: 'environment',
    displayText: 'Environment',
    icon: EnvironmentIcon,
  },
  {
    key: 'ethics',
    displayText: 'Ethics',
    icon: EthicsIcon,
  },
  {
    key: 'finance',
    displayText: 'Finance',
    icon: FinanceIcon,
  },
  {
    key: 'human-rights',
    displayText: 'Human Rights',
    icon: HumanRightsIcon,
  },
  {
    key: 'health-care',
    displayText: 'Health Care',
    icon: HealthCareIcon,
  },
  {
    key: 'immigration',
    displayText: 'Immigration',
    icon: ImmigraitonIcon,
  },
  {
    key: 'infrastructure',
    displayText: 'Infrastructure',
    icon: InfrastructureIcon,
  },
  {
    key: 'poverty',
    displayText: 'Poverty',
    icon: PovertyIcon,
  },
  {
    key: 'social-issues',
    displayText: 'Social Issues',
    icon: SocialIssuesIcon,
  },
  {
    key: 'other',
    displayText: 'Other',
    icon: CheckBoxOutlineBlankRoundedIcon,
  },
]

export function getCategoryDisplayText(category: string) {
  for (const c of categories) {
    if (c.key === category) {
      return c.displayText
    }
  }
  return undefined
}
