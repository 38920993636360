import React from 'react'
import Button from '@material-ui/core/Button'

// TODO make width, height configurable

interface ImageUploadProps {
  initialImgSrc: string
  onChange: (newImageUrl: string, newImageWidth: number, newImageHeight: number) => void
}

interface ImageUploadState {
  changed: boolean
}

class ImageUpload extends React.Component<ImageUploadProps, ImageUploadState> {
  state = {
    changed: false,
  }

  canvasRef = React.createRef<HTMLCanvasElement>()
  img: HTMLImageElement

  constructor(props: ImageUploadProps) {
    super(props)
    this.img = new Image()
    this.img.crossOrigin = 'anonymous'
  }

  componentDidMount() {
    this.img.onload = this.onImageLoad
    if (this.props.initialImgSrc) {
      this.img.src = this.props.initialImgSrc
    }
  }
  // componentDidUpdate() {
  //   if (this.props.initialImgSrc && this.img.src !== this.props.initialImgSrc) {
  //     this.img.src = this.props.initialImgSrc
  //   }
  // }

  onImageLoad = () => {
    const c = this.canvasRef.current
    // console.log('theCanvas', this.canvasRef.current)
    if (c) {
      const context = c.getContext('2d')
      if (context) {
        const w = this.img.naturalWidth
        const h = this.img.naturalHeight
        let sx = 0
        let sy = 0
        const size = Math.min(w, h)
        if (w > h) {
          sx = (w - h) / 2
          sy = 0
        } else {
          sx = 0
          sy = (h - w) / 2
        }
        // c.width = this.img.naturalWidth
        // c.height = this.img.naturalHeight
        c.width = size
        c.height = size
        context.drawImage(this.img, sx, sy, size, size, 0, 0, size, size)

        const croppedDataUrl = c.toDataURL('image/jpeg', 0.92)
        if (this.state.changed) {
          this.props.onChange(croppedDataUrl, size, size)
        }
      }
    }
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('onChange', canvasRef)
    const files = e.target.files
    if (files && files.length > 0) {
      // console.log('file', files[0])
      // setSelectedFile(files[0])
      const file = files[0]
      if (file.type.match('image.*')) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = evt => {
          // console.log('onLoad', canvasRef, currentCanvas)
          const target = evt.target as any
          if (target && target.readyState === FileReader.DONE) {
            if (typeof target.result === 'string') {
              this.img.src = target.result
              this.setState({ changed: true })
              // this.props.onChange(target.result, -1, -1)
            }
          }
        }
      } else {
        alert('not an image')
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <canvas
          id='theCanvas'
          style={{ margin: 10, border: '1px solid #aaa', width: 300, height: 300 }}
          ref={this.canvasRef}
          onDragOver={e => {
            e.preventDefault()
          }}
          onDrop={e => {
            console.log('on drop', e)
            e.preventDefault()
            const files = e.dataTransfer.files
            if (files.length > 0) {
              const file = files[0]
              const reader = new FileReader()

              reader.onload = onLoadEvent => {
                const target = onLoadEvent.target as any

                if (target && typeof target.result === 'string') {
                  this.img.src = target.result
                  this.setState({ changed: true })
                }
              }
              reader.readAsDataURL(file)
            }
          }}
        />
        {/* <div>
          <Button>Browse Files</Button> or Drag and Drop
          <input type='file' onChange={this.onChange} />
        </div> */}

        <div>
          <input accept='image/*' style={{ display: 'none' }} id='raised-button-file' type='file' onChange={this.onChange} />
          <label htmlFor='raised-button-file'>
            <Button variant='contained' component='div'>
              Browse Files
            </Button>
          </label>
        </div>
        <div style={{ marginTop: 10 }}>(or drop image above)</div>
      </React.Fragment>
    )
  }
}

export default ImageUpload
