import React from 'react'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import AnimatedTypography from '../components/AnimatedTypography'
import IconButton from '@material-ui/core/IconButton'
import { Vote } from '../firebase/currentVoteForBallot'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined'
// import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined'
import Typography from '@material-ui/core/Typography'
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined'
import FacebookIcon from '@material-ui/icons/Facebook'

const useStyles = makeStyles(() => ({
  divTable: {
    display: 'table',
    // width: 100%;
  },
  divTableRow: {
    display: 'table-row',
  },
  divTableCell: {
    display: 'table-cell',
    textAlign: 'center',
  },
  buttonLabel: {
    // Aligns the content of the button vertically.
    flexDirection: 'column',
  },
  nothing: {},
  yay: { color: '#008800' },
  nay: { color: '#cc0000' },
  askQuestion: { color: 'rgb(204, 150, 0)' },
  donate: { color: '#6600cc' },
  facebook: { color: '#3b5998' },
}))

interface Props {
  voteState: Vote
  yaysText?: string
  naysText?: string
  onVote: (value: boolean) => void
  onAskQuestion: () => void
  onShareFacebook: () => void
  buttonsDisabled: boolean
  askDisabled: boolean
}

const BallotButtons: React.FC<Props> = props => {
  const classes = useStyles(props)
  const { voteState, yaysText, naysText, onVote, buttonsDisabled, askDisabled } = props

  const yayClass = buttonsDisabled ? classes.nothing : classes.yay
  const nayClass = buttonsDisabled ? classes.nothing : classes.nay
  const askQuestionClass = buttonsDisabled || askDisabled ? classes.nothing : classes.askQuestion
  // const donateClass = buttonsDisabled ? classes.nothing : classes.donate
  const facebookClass = buttonsDisabled ? classes.nothing : classes.facebook

  return (
    <div>
      <Divider style={{ marginTop: 12 }} />
      <div className={classes.divTable}>
        <div className={classes.divTableRow}>
          <AnimatedTypography className={classes.divTableCell} variant='body1' text={yaysText}></AnimatedTypography>
          <AnimatedTypography className={classes.divTableCell} variant='body1' text={naysText}></AnimatedTypography>
        </div>
        <div className={classes.divTableRow}>
          <div className={classes.divTableCell}>
            <IconButton aria-label='Yea' classes={{ label: classes.buttonLabel }} onClick={() => onVote(true)} disabled={buttonsDisabled}>
              {voteState === true ? (
                <ThumbUpIcon fontSize='large' className={yayClass} />
              ) : (
                <ThumbUpOutlinedIcon fontSize='large' className={yayClass} />
              )}
              <Typography>Yea</Typography>
            </IconButton>
          </div>
          <div className={classes.divTableCell}>
            <IconButton aria-label='Nay' classes={{ label: classes.buttonLabel }} onClick={() => onVote(false)} disabled={buttonsDisabled}>
              {voteState === false ? (
                <ThumbDownIcon fontSize='large' className={nayClass} />
              ) : (
                <ThumbDownOutlinedIcon fontSize='large' className={nayClass} />
              )}
              <Typography>Nay</Typography>
            </IconButton>
          </div>
          <div className={classes.divTableCell}>
            <IconButton
              id='askQuestion'
              aria-label='ask question'
              classes={{ label: classes.buttonLabel }}
              disabled={buttonsDisabled || askDisabled}
              onClick={props.onAskQuestion}
            >
              <QuestionAnswerOutlinedIcon fontSize='large' className={askQuestionClass} />
              <Typography style={{ whiteSpace: 'nowrap' }}>Ask Question</Typography>
            </IconButton>
          </div>
          {/* <div className={classes.divTableCell}>
            <IconButton aria-label='Donate' classes={{ label: classes.buttonLabel }} disabled={buttonsDisabled}>
              <AttachMoneyOutlinedIcon fontSize='large' className={donateClass} />
              <Typography>Donate</Typography>
            </IconButton>
          </div> */}
          <div className={classes.divTableCell}>
            <IconButton
              aria-label='Share'
              classes={{ label: classes.buttonLabel }}
              disabled={buttonsDisabled}
              onClick={props.onShareFacebook}
            >
              <FacebookIcon fontSize='large' className={facebookClass} />
              <Typography>Share</Typography>
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BallotButtons
