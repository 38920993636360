import { Action } from 'redux'
import { SetValueAction } from '../firebase/dbSubscriptions'

export default interface UnreadItem {
  ballotId: string
  count: number
  headline: string
  type: string
  slug: string
}

export function unreadItemsReducer(state: UnreadItem[] | null = [], action: Action) {
  switch (action.type) {
    case 'SET_UNREAD_ITEMS':
      return (action as SetValueAction<UnreadItem[]>).value
    default:
      return state
  }
}
