import { Action, ActionCreator, Reducer } from 'redux'
import { SelectOption } from '../utils/SelectOptions'

export const sortByOptions: SelectOption[] = [
  {
    name: 'newest',
    displayText: 'Newest',
  },
  {
    name: 'mostActive',
    displayText: 'Most Active',
  },
]

export default interface BallotQuery {
  sortBy?: string
  direction?: 'asc' | 'desc'
  searchText?: string
  // category: string
  author?: string | null
}

export const initialBallotQuery: BallotQuery = {
  sortBy: 'createdAt',
  direction: 'desc',
  // query: '',
  // category: 'all',
}

interface SetSortByAction extends Action {
  sortBy: string
  direction: 'asc' | 'desc'
}
interface SetCategoryAction extends Action {
  category: string
}
interface SetSearchTextAction extends Action {
  searchText?: string
}

type BallotQueryActions = SetSortByAction | SetCategoryAction | SetSearchTextAction

export const setSortByActionCreator: ActionCreator<SetSortByAction> = (sortBy: string, direction: 'asc' | 'desc') => ({
  type: 'SET_QUERY_SORT_BY',
  sortBy,
  direction,
})
export const setCategoryActionCreator: ActionCreator<SetCategoryAction> = (category: string) => ({
  type: 'SET_QUERY_CATEGORY',
  category,
})
export const setSearchTextActionCreator: ActionCreator<SetSearchTextAction> = (searchText?: string) => ({
  type: 'SET_QUERY_SEARCH_TEXT',
  searchText,
})

export const ballotQueryActionTypes = ['SET_QUERY_SORT_BY', 'SET_QUERY_CATEGORY', 'SET_QUERY_SEARCH_TEXT']

export const ballotQueryReducer: Reducer<BallotQuery, BallotQueryActions> = (state = initialBallotQuery, action) => {
  switch (action.type) {
    case 'SET_QUERY_SORT_BY':
      return { ...state, sortBy: (action as SetSortByAction).sortBy }
    case 'SET_QUERY_CATEGORY':
      return { ...state, category: (action as SetCategoryAction).category }
    case 'SET_QUERY_SEARCH_TEXT':
      return { ...state, searchText: (action as SetSearchTextAction).searchText }
    default:
      return state
  }
}
