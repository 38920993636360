import firebase from 'firebase/app'
import 'firebase/firestore'
import Ballot from '../model/Ballot'
import { SubscriberReturnValue, dbSubscriber } from './dbSubscriptions'
import { QuestionAndAnswer, QuestionAndAnswers } from '../model/QuestionAndAnswer'
import UnreadItem from '../model/UnreadItems'

function unreadItemsTransforer(snapshot: firebase.firestore.QuerySnapshot): UnreadItem[] {
  console.log('got unreadItemss')
  const unreadItemsList: UnreadItem[] = []
  snapshot.forEach(docSnapshot => {
    const docData = docSnapshot.data()
    console.log('unreadItems data', docData)
    if (docData) {
      const unreadItem: UnreadItem = docData as UnreadItem
      unreadItemsList.push(unreadItem)
    }
  })
  return unreadItemsList
}

export function unreadItemslSubscriber(userId: string): SubscriberReturnValue<Ballot> {
  return dbSubscriber('SET_UNREAD_ITEMS', unreadItemsTransforer, ['unread', userId, 'unread'], null, null, null, null)
}
