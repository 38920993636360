import React, { CSSProperties } from 'react'
import { Dispatch, AnyAction } from 'redux'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { State } from '../store/State'
import { CurrentUser } from '../model/AuthenticatedUser'
import RouteButton from '../components/RouteButton'
import { signInToRoute } from '../firebase/auth'

const usesStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const buttonStyle: CSSProperties = {
  margin: 10,
  // marginTop: 50,
}

interface HomeProps {
  user: CurrentUser
  signIn: () => void
}

const Home: React.FC<HomeProps> = (props: HomeProps) => {
  const classes = usesStyles()
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignContent: 'center',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
      }}
    >
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ flexGrow: 1, minHeight: 38 }}></div>
        {props.user === null ? (
          <Button id='signIn' onClick={props.signIn} color='inherit' style={{ marginRight: 30 }}>
            Sign In
          </Button>
        ) : (
          undefined
        )}
      </div>
      <div
        style={{
          paddingTop: 50,
          paddingBottom: 20,
          fontSize: 90,
          fontFamily: 'Quicksand',
          // flexGrow: 2,
        }}
      >
        SayeSo
      </div>
      <Typography variant='h4' style={{ fontWeight: 'bold' }}>
        Civic Crowdsourcing
      </Typography>
      <Typography variant='body1' style={{ padding: '30px 18%' }}>
        Sayeso is a digital platform to present issues and solicit input through voting and questions. Allowing votes on issues instead of
        people creates a more direct avenue to gather consensus and questions regarding issues that people are trying to solve. Aggregating
        the voices (through voting) of thousands of people on an issue can prove to be a powerful tool. Crowdsourcing voices can allow for
        direct influence on public policy. Let’s crowdsource our ideas and let everyone have their Sayeso.
      </Typography>
      <div className={classes.buttonContainer}>
        <RouteButton id='exploreButton' to='/explore' variant='contained' color='primary' style={buttonStyle}>
          Explore Ballots
        </RouteButton>
        <RouteButton id='createButton' to='/create-ballot' variant='contained' color='primary' style={buttonStyle}>
          Create a Ballot
        </RouteButton>
      </div>
      <div style={{ flexGrow: 1 }}></div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <a style={{ padding: 5 }} href='/terms.html'>
            Terms
          </a>
          {' | '}
          <a style={{ padding: 5 }} href='/privacy.html'>
            Privacy
          </a>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: State) => ({
  user: state.user,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  signIn: () => signInToRoute('/explore', dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home)
