import firebase from 'firebase/app'
import 'firebase/firestore'
import Ballot, { emptyBallot } from '../model/Ballot'
import { SubscriberReturnValue, dbSubscriber } from './dbSubscriptions'

function ballotTransforer(snapshot: firebase.firestore.QuerySnapshot): Ballot | null {
  console.log('got ballotQuery', snapshot, snapshot.size)
  if (snapshot.size === 0) {
    return null
  }
  const docSnapshot = snapshot.docs[0]
  const data = docSnapshot.data()
  if (data) {
    const ballot: Ballot = {
      id: docSnapshot.id,
      slug: data.slug,
      headline: data.headline,
      category: data.category,
      description: data.description,
      photoUrl: data.photoUrl,
      ogUrl: data.ogUrl,
      authorId: data.authorId,
      authorName: data.authorName,
      authorPhotoUrl: data.authorPhotoUrl,
      authorSlug: data.authorSlug,
      authorEmail: data.authorEmail,
      draft: data.draft,
      createdAt: data.createdAt.toDate(),
      updatedAt: data.updatedAt.toDate(),
      yays: data.yays,
      nays: data.nays,
      totalVotes: data.totalVotes,
      googlePlace: data.googlePlace,
    }
    return ballot
  }
  return emptyBallot
}

export function ballotSubscriber(slug: string): SubscriberReturnValue<Ballot> {
  return dbSubscriber('SET_PROPOSAL', ballotTransforer, ['props'], ['slug', '==', slug], null, null, null)
}
