import { Action } from 'redux'
import { SelectOption } from '../utils/SelectOptions'
import { GooglePlace } from '../components/GoogleMapsLocationSearch'
import { QuestionAndAnswer } from './QuestionAndAnswer'
import { SetValueAction } from '../firebase/dbSubscriptions'
import { convertFromRaw } from 'draft-js'
import User from '../model/AuthenticatedUser'

export const categories = ['', 'education', 'infrastructure', 'puppies']
export const categorySelectOptions: SelectOption[] = [
  {
    name: 'all',
    displayText: 'All Categories',
  },
  {
    name: 'education',
    displayText: 'Education',
  },
  {
    name: 'infrastructure',
    displayText: 'Infrastructure',
  },
  {
    name: 'puppies',
    displayText: 'Puppies',
  },
]

export default interface Ballot {
  id?: string
  slug?: string
  headline: string
  category: string
  googlePlace?: GooglePlace
  description: string
  photoUrl?: string
  ogUrl?: string
  authorId: string | null
  authorName: string | null
  authorPhotoUrl: string | null
  authorSlug: string | null
  authorEmail: string | null
  draft: boolean
  createdAt: Date | null
  updatedAt: Date | null
  yays: number
  nays: number
  totalVotes: number
}

export const emptyBallot: Ballot = {
  id: undefined,
  headline: '',
  category: '',
  description: '',
  authorId: null,
  authorName: null,
  authorPhotoUrl: null,
  authorSlug: null,
  authorEmail: null,
  draft: true,
  createdAt: null,
  updatedAt: null,
  yays: 0,
  nays: 0,
  totalVotes: 0,
}

interface SetCurrentBallotAction extends Action {
  value: Ballot | null
}

export const createSetCurrentBallotAction = (ballot: Ballot | null): SetCurrentBallotAction => ({
  type: 'SET_PROPOSAL',
  value: ballot,
})

export function ballotReducer(state: Ballot | null = null, action: Action) {
  switch (action.type) {
    case 'SET_PROPOSAL':
      return (action as SetCurrentBallotAction).value
    case 'SET_QANDAS':
      if (!state) {
        return state
      }
      return { ...state, qandas: (action as SetValueAction<QuestionAndAnswer[]>).value }
    default:
      return state
  }
}

interface SetDraftBallotAction extends Action {
  value: Ballot
}

export const createSetDraftBallotAction = (ballot: Ballot): SetDraftBallotAction => ({
  type: 'SET_DRAFT_PROPOSAL',
  value: ballot,
})

export function draftBallotReducer(state: Ballot = emptyBallot, action: Action) {
  switch (action.type) {
    case 'SET_DRAFT_PROPOSAL':
      return (action as SetDraftBallotAction).value
    default:
      return state
  }
}

export function getDescriptionText(ballot: Ballot): string {
  return convertFromRaw(JSON.parse(ballot.description)).getPlainText()
}
