import React, { CSSProperties } from 'react'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import SendIcon from '@material-ui/icons/Send'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'

import Ballot from '../model/Ballot'
import { UserAvatar } from './UserAvatar'
import { State } from '../store/State'
import { addQuestion, setAnswer } from '../firebase'
import { Dispatch, AnyAction } from 'redux'
import { connect, DispatchProp } from 'react-redux'
import { qandalSubscriber } from '../firebase/qandaSubscriber'
import { QuestionAndAnswers } from '../model/QuestionAndAnswer'
// import { UserCache } from '../firebase/UserCache'
import moment from 'moment'
import User, { CurrentUser, getAuthenticatedUser, UNKNOWN_AUTH } from '../model/AuthenticatedUser'

// const styles = () =>
//   createStyles({
//     container: {
//       marginTop: 10,
//       marginBottom: 10,
//       padding: 10,
//       display: 'flex',
//       alignItems: 'center',
//     },
//     input: {
//       flexGrow: 1,
//     },
//     heading: {
//       marginTop: 10,
//     },
//     noQuestions: {
//       fontSize: '0.75rem',
//       color: '#aaa',
//       marginTop: 15,
//     },
//     qAndA: {},
//   })

// interface BallotQAProps extends WithStyles<typeof styles> {
interface BallotQAProps extends DispatchProp {
  // edit?: boolean
  // ballotId: string | undefined
  ballot: Ballot
  saveQuestion: (ballotId: string, content: string, user: User) => void
  saveAnswer: (ballotId: string, qandaId: string, content: string) => void
  qandas: QuestionAndAnswers
  // userCache: UserCache
  ballotOwnerId: string | null
  currentUser: CurrentUser
}

interface BallotQAState {
  asking: boolean
  answeringQuestionId: string | null
  sentAnswersFor: string[]
  needScrollToBottom: boolean
}

interface StyleSheet {
  [key: string]: CSSProperties
}

const styles: StyleSheet = {
  container: {
    marginTop: 10,
    marginBottom: 10,
    padding: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  input: {
    flexGrow: 1,
  },
  heading: {
    marginTop: 10,
  },
  noQuestions: {
    fontSize: '0.75rem',
    color: '#aaa',
    marginTop: 15,
  },
  deemphasized: {
    fontSize: '0.75rem',
    color: '#888', // theme.typography.subtitle1.color,
  },
}

export class BallotQA extends React.Component<BallotQAProps, BallotQAState> {
  state: BallotQAState = {
    asking: false,
    answeringQuestionId: null,
    sentAnswersFor: [],
    needScrollToBottom: false,
  }

  questionRef = React.createRef<HTMLInputElement>()
  answerRef = React.createRef<HTMLInputElement>()
  bottomRef = React.createRef<HTMLDivElement>()

  componentDidMount = () => {
    if (this.props.ballot && this.props.ballot.id) {
      qandalSubscriber(this.props.ballot.id).subscribe(this.props.dispatch)
    }
  }
  componentDidUpdate = () => {
    this.scrollToEditor()
  }

  componentWillUnmount = () => {
    if (this.props.ballot && this.props.ballot.id) {
      qandalSubscriber(this.props.ballot.id).unsubscribe()
    }
  }

  render = () => {
    // console.log('rendering qanda', this.props.qandas)
    return (
      <React.Fragment>
        <Typography variant='h6' style={styles.heading}>
          Questions and Answers
        </Typography>
        {Object.keys(this.props.qandas).map(qandaId => this.renderQandA(qandaId))}
        {this.state.asking && (
          <React.Fragment>
            <Paper style={styles.container}>
              <div style={{ display: 'flex', width: '100%', padding: 4 }}>
                <TextField
                  id='questionInput'
                  multiline={true}
                  variant='outlined'
                  inputRef={this.questionRef}
                  style={styles.input}
                  placeholder='Ask a question...'
                  autoFocus
                />
                <IconButton id='questionSubmit' color='primary' aria-label='send' onClick={this.sendQuestion}>
                  <SendIcon />
                </IconButton>
              </div>
            </Paper>
          </React.Fragment>
        )}
        {!this.state.asking && Object.keys(this.props.qandas).length === 0 && (
          <Typography style={styles.noQuestions}>No questions have been asked.</Typography>
        )}
        <div style={{ minHeight: 2 }} ref={this.bottomRef}></div>
      </React.Fragment>
    )
  }

  renderQandA(qandaId: string) {
    const qanda = this.props.qandas[qandaId]
    // console.log('rendering qanda', qanda)
    const ballot = this.props.ballot
    const currentUserOrNull = getAuthenticatedUser(this.props.currentUser)
    const sentAnswerFor = this.sentAnswer(qandaId)
    return (
      <Paper key={qandaId} style={styles.container} id='questionAndAnswer'>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }} id='question'>
          <UserAvatar
            to={`/profiles/${qanda.question.askerSlug}`}
            displayName={qanda.question.askerDisplayName}
            photoUrl={qanda.question.askerPhotoUrl}
          />
          <div style={{ flexGrow: 1 }}>
            <Typography>{qanda.question.content}</Typography>
            <Typography style={styles.deemphasized}>Asked {moment(qanda.question.createdAt).fromNow()}</Typography>
          </div>
        </div>
        {qanda.answer && (
          <div style={{ width: '100%' }} id='answer'>
            <Divider variant='middle' />
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <UserAvatar to={`/profiles/${ballot.authorSlug}`} displayName={ballot.authorName} photoUrl={ballot.authorPhotoUrl} />
              <div style={{ flexGrow: 1 }}>
                <Typography>{qanda.answer.content}</Typography>
                <Typography style={styles.deemphasized}>Answered {moment(qanda.answer.createdAt).fromNow()}</Typography>
              </div>
            </div>
          </div>
        )}
        {currentUserOrNull && this.props.ballotOwnerId === currentUserOrNull.uid && !qanda.answer && (
          <div style={{ width: '100%' }}>
            <Divider variant='middle' />
            {!sentAnswerFor && this.state.answeringQuestionId !== qandaId && (
              <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: 4 }}>
                <div style={{ flexGrow: 1 }}></div>
                <Button id='answerButton' onClick={() => this.setState({ answeringQuestionId: qandaId, needScrollToBottom: true })}>
                  Answer
                </Button>
                <div style={{ flexGrow: 1 }}></div>
              </div>
            )}
            {!sentAnswerFor && this.state.answeringQuestionId === qandaId && (
              <React.Fragment>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: 4 }}>
                  <TextField
                    multiline={true}
                    variant='outlined'
                    inputRef={this.answerRef}
                    style={styles.input}
                    placeholder='Answer question...'
                    autoFocus={true}
                  />
                  <IconButton color='primary' aria-label='send' onClick={() => this.sendAnswer(qandaId)}>
                    <SendIcon />
                  </IconButton>
                </div>
                {/* <div style={{ minHeight: 2 }} ref={this.bottomRef}></div> */}
              </React.Fragment>
            )}
            {sentAnswerFor && <div style={{ minHeight: '2rem' }}></div>}
          </div>
        )}
      </Paper>
    )
  }

  // currentUser

  startEditing = () => {
    console.log('startEditing', this.state.asking)
    if (!this.state.asking) {
      this.setState({ asking: true, needScrollToBottom: true })
    }
  }

  private scrollToEditor = () => {
    if (this.state.needScrollToBottom) {
      this.setState({ needScrollToBottom: false })
      if (this.bottomRef.current) {
        this.bottomRef.current.scrollIntoView(false)
      }
    }
  }

  private sendQuestion = () => {
    if (this.questionRef.current && this.props.ballot.id) {
      const questionContent = this.questionRef.current.value
      console.log('send question', questionContent)
      if (questionContent !== '' && this.props.currentUser && this.props.currentUser !== UNKNOWN_AUTH) {
        this.props.saveQuestion(this.props.ballot.id, this.questionRef.current.value, this.props.currentUser)
        this.setState({ asking: false, needScrollToBottom: true })
      }
    }
  }

  private sendAnswer = (questionId: string) => {
    const answerValue = this.answerRef.current && this.answerRef.current.value
    this.setState({ answeringQuestionId: null })
    if (!answerValue || answerValue === '') {
      return
    }
    console.log('setting answer', questionId, answerValue)

    this.setState({ sentAnswersFor: [...this.state.sentAnswersFor, questionId] })
    if (this.props.ballot && this.props.ballot.id) {
      this.props.saveAnswer(this.props.ballot.id, questionId, answerValue)
    }
  }

  private sentAnswer: (id: string) => boolean = id => {
    const rv = this.state.sentAnswersFor.includes(id)
    return rv
  }
}

// const x = withStyles(styles)(BallotQA)

// export default withStyles(styles)(BallotQA)

function mapStateToProps(state: State) {
  return {
    qandas: state.currentBallotQandAs,
    // userCache: state.userCache,
    ballotOwnerId: state.currentBallot ? state.currentBallot.authorId : null,
    // ballot: state.currentBallot!,
    currentUser: state.user,
  }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return {
    saveQuestion: addQuestion,
    saveAnswer: setAnswer,
    dispatch,
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BallotQA)
