import React, { useState } from 'react'
import { connect } from 'react-redux'
import User, { CurrentUser, UNKNOWN_AUTH } from '../model/AuthenticatedUser'
import { State } from '../store/State'
import LoadingScreen from './Loading'
import ProfileEdit from './ProfileEdit'
import ProfileView from './ProfileView'
import { getUser, getUserProfile, getUserProfileBySlug, getUserBySlug } from '../firebase'
import UserProfile from '../model/UserProfile'
// import { UserCache } from '../firebase/UserCache'
import { AnyAction, Dispatch } from 'redux'
import { replace } from 'connected-react-router'

interface ProfileProps {
  viewUserId?: string
  // uid?: string
  currentUser: CurrentUser
  type: 'edit' | 'view'
  // userCache: UserCache
  navigateToHome: () => void
}

const Profile: React.FunctionComponent<ProfileProps> = props => {
  const [user, setUser] = useState<User | null>(null)
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null)
  const [userProfileRequested, setUserProfileRequested] = useState<boolean>(false)

  const { type, currentUser } = props
  // const viewUser: User | null = null // = props.viewUserId ? props.userCache[props.viewUserId] : null
  // console.log('rendering Profile viewUser', viewUser)

  const userGetter = type === 'edit' ? getUser : getUserBySlug
  let userId: string
  if (type === 'edit') {
    if (currentUser === UNKNOWN_AUTH) {
      return <LoadingScreen />
    }
    if (currentUser === null) {
      props.navigateToHome()
      return <div></div>
    }
    userId = currentUser.uid
  } else {
    // assume viewUserId is always passed in when in viewing mode
    userId = props.viewUserId!
  }

  // const user = type === 'edit' ? currentUser : viewUser
  // console.log('rendering Profile', user, currentUser)

  if (!userProfileRequested) {
    setUserProfileRequested(true)

    // we have an authenticated user and the userProfile has not yet been requested

    if (!user) {
      void userGetter(userId).then(u => {
        setUser(u)
        if (u) {
          console.log('getting user profile', u.uid)
          void getUserProfile(u.uid).then(uprofile => {
            console.log('back from getUserProfile', uprofile)
            if (uprofile) {
              setUserProfile(uprofile)
            } else {
              // user profile does not yet exist, create a blank one
              const blankUserProfile: UserProfile = {
                fullPhotoUrl: null,
                bio: '',
              }

              // prepopulate the fullPhotoUrl with one that we may have gotten from auth
              // blankUserProfile.fullPhotoUrl = user.photoUrl
              setUserProfile(blankUserProfile)
            }
          })
        }
      })
    }
  }

  if (user === null || userProfile === null) {
    return <LoadingScreen />
  }
  if (props.type === 'edit') {
    if (!currentUser || currentUser === UNKNOWN_AUTH) {
      return <LoadingScreen />
    }
    return <ProfileEdit user={currentUser} userProfile={userProfile} />
  }
  return <ProfileView user={user} userProfile={userProfile} />
}

function mapStateToProps(state: State) {
  return {
    // userCache: state.userCache,
    currentUser: state.user,
  }
}
function mapStateToDispatch(dispatch: Dispatch<AnyAction>) {
  return {
    navigateToHome: () => dispatch(replace('/')),
  }
}

export default connect(mapStateToProps, mapStateToDispatch)(Profile)
