import React from 'react'
import Paper from '@material-ui/core/Paper'
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles'
import RichTextEditor, { MUIRichTextEditor } from '../components/rte/MUIRichTextEditor'
import TextField from '@material-ui/core/TextField'
import ImageUpload from '../components/ImageUpload'
import User, { createUserAuthAction } from '../model/AuthenticatedUser'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { Debounce } from '../utils/Debounce'
import { saveUserPhotos, putUser, putUserProfile } from '../firebase'
import deepEqual from 'deep-equal'
import { Dispatch, AnyAction } from 'redux'
import { EditorState } from 'draft-js'
import UserProfile from '../model/UserProfile'
import ContentSqueezer from '../components/ContentSqueezer'

// const useStyles = makeStyles((theme) => ({
//   container: {
//     margin: 10,
//     padding: 10,
//   },
//   imageContainer: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     width: '100%',
//     padding: 10,
//   },
// }))

const styles = () =>
  createStyles({
    container: {
      margin: 10,
      padding: 10,
    },
    imageContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: 10,
    },
  })

interface ProfileEditProps extends WithStyles<typeof styles> {
  user: User
  userProfile: UserProfile
  updateUser: (authenticatedUser: User) => void
}

interface ProfileEditState {
  displayName: string
  photoUrl: string | null
  photoWidth: number
  photoHeight: number
  bioJson: string
}

const editorButtons = ['title', 'bold', 'italic', 'underline', 'link', 'numberList', 'bulletList', 'quote']

class ProfileEdit extends React.Component<ProfileEditProps, ProfileEditState> {
  state = {
    displayName: this.props.user.displayName ? this.props.user.displayName : '',
    photoUrl: this.props.userProfile.fullPhotoUrl,
    photoWidth: -1,
    photoHeight: -1,
    bioJson: this.props.userProfile.bio,
  }
  private debounceSaveProfile = new Debounce(() => this.state, this.saveProfile.bind(this), 10000)
  private bioRef = React.createRef<MUIRichTextEditor>()

  componentWillUnmount() {
    this.debounceSaveProfile.cancel()
    void this.saveProfile(this.state)
  }

  render() {
    // console.log('rendering profile edit', this.props, this.state)
    const isCordova = window.location.protocol === 'file:'
    const prefix = isCordova ? '' : '/'
    const accountCircle = prefix + 'account-circle-300x300.png'

    return (
      <ContentSqueezer>
        <Paper className={this.props.classes.container}>
          <Typography style={{ marginBottom: 10 }} variant='h4'>
            Edit Profile
          </Typography>
          <TextField
            variant='outlined'
            label='Display Name'
            id='displayName'
            fullWidth
            value={this.state.displayName}
            onChange={e => this.updateState({ ...this.state, displayName: e.target.value })}
            placeholder='Display Name'
          />
          <div className={this.props.classes.imageContainer}>
            <ImageUpload
              initialImgSrc={this.state.photoUrl || accountCircle}
              onChange={(newPhotoUrl, newWidth, newHeight) =>
                this.updateState({ ...this.state, photoUrl: newPhotoUrl, photoWidth: newWidth, photoHeight: newHeight })
              }
            />
          </div>
          <div style={{ minHeight: 40, border: 'solid 1px #ddd', borderRadius: 4 }}>
            <RichTextEditor
              value={this.state.bioJson}
              ref={this.bioRef}
              label='Say something intersting about yourself...'
              controls={editorButtons}
              onChange={(state: EditorState) => console.log('bio change', state)}
            />
          </div>
        </Paper>
      </ContentSqueezer>
    )
  }

  private updateState(state: ProfileEditState) {
    // console.log('updateState', state)
    this.setState(state)
    this.debounceSaveProfile.setValue(state)
  }

  private async saveProfile(state: ProfileEditState) {
    console.log('save profile', state)
    const bio = this.bioRef.current ? this.bioRef.current.getValue() : null
    console.log('bio', bio)
    const newAuthenticatedUser: User = {
      ...this.props.user,
      displayName: this.state.displayName,
      photoUrl: this.state.photoUrl,
    }
    const newUserProfile: UserProfile = {
      fullPhotoUrl: null,
      bio: bio ? bio : '',
    }

    if (state.photoUrl && !state.photoUrl.startsWith('http')) {
      const [fullPhotoUrl, photoUrl] = await saveUserPhotos(state.photoUrl, state.photoWidth, state.photoHeight)
      newUserProfile.fullPhotoUrl = fullPhotoUrl
      newAuthenticatedUser.photoUrl = photoUrl
    } else {
      newAuthenticatedUser.photoUrl = this.props.user.photoUrl
      newUserProfile.fullPhotoUrl = state.photoUrl
    }
    if (!deepEqual(newAuthenticatedUser, this.props.user)) {
      void putUser(newAuthenticatedUser)
      this.props.updateUser(newAuthenticatedUser)
    } else {
      console.log('not saving identical authenticatedUser')
    }
    if (!deepEqual(this.props.userProfile, newUserProfile)) {
      void putUserProfile(this.props.user.uid, newUserProfile)
    } else {
      console.log('not saving identical userProfile')
    }
  }
}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return {
    updateUser: (user: User) => dispatch(createUserAuthAction(user)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ProfileEdit))
