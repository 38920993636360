import React from 'react'
import { Location } from 'history'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import MobileStepper from '@material-ui/core/MobileStepper'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { categories } from '../components/categories'
import ContentSqueezer from '../components/ContentSqueezer'
import { TextField } from '@material-ui/core'
import RichTextEditor, { MUIRichTextEditor } from '../components/rte/MUIRichTextEditor'
import { EditorState, convertToRaw } from 'draft-js'
import ImageUpload from '../components/ImageUpload'
import { BallotViewPartial } from './BallotView'
import Ballot, { createSetDraftBallotAction, emptyBallot } from '../model/Ballot'
import { connect } from 'react-redux'
import { State } from '../store/State'
import { getUidOrNull, CurrentUser } from '../model/AuthenticatedUser'
import { push, goBack } from 'connected-react-router'
import { Dispatch, Action } from 'redux'
import { authenticate } from '../firebase/auth'
import { putBallot } from '../firebase'
import { useAlertDialog } from '../components/AlertDialog'
import GoogleMap from '../components/GoogleMap'
import { GooglePlace } from '../components/GoogleMapsLocationSearch'
import { resizeDataUrl } from '../utils/resizeImage'

const CATEGORIES_PAGE = 0
const TITLE_PAGE = 1
const DESCRIPTION_PAGE = 2
const LOCATON_PAGE = 3
const IMAGE_PAGE = 4

const CATEGORY_ICON_SIZE = 72
const PHOTO_SIZE = 600
const VIEWPORT_WIDTH_THRESHOLD = 800

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '90%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    selectedCategory: {
      color: theme.palette.primary.main,
      // border: 'solid blue 2px',
      borderStyle: 'solid',
      borderWidth: '2px',
      borderColor: theme.palette.primary.main,
      borderRadius: 6,
    },
    notSelectedCategory: {
      color: theme.palette.grey[500],
    },
    imageContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: 10,
    },
    buttonProgress: {
      color: 'white',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
)

interface Step {
  title: string
  hash: string
}

const getSteps = () => {
  return [
    {
      title: 'Select a category',
      hash: 'category',
    },
    {
      title: 'Write a title',
      hash: 'title',
    },
    {
      title: 'Draft the ballot',
      hash: 'description',
    },
    {
      title: 'Set a location',
      hash: 'location',
    },
    {
      title: 'Add a photo',
      hash: 'image',
    },
  ]
}

interface PhotoState {
  url: string
  width: number
  height: number
}

interface BallotCreateProps {
  location: Location
  ballot?: Ballot
  uid: string | null
  preview: boolean
  navTo: (location: Location) => void
  navBack: () => void
  saveBallot: (ballot: Ballot, currentUser: CurrentUser) => Promise<string>
  postSaveBallot: (ballotId: string) => void
  authenticate: (postAuthActions: Action[]) => void
  createNavToCurrentLocationAction: () => Action
  currentUser: CurrentUser
}

const BallotCreate: React.FC<BallotCreateProps> = props => {
  const classes = useStyles()
  const steps = getSteps()
  const dialog = useAlertDialog()

  const { ballot } = props
  console.log('create ballot, existing ballot', ballot)
  const hash = props.location.hash.substring(1)
  const pageIndex = steps.findIndex(x => x.hash === hash)
  console.log('hash', props.location.hash, pageIndex)
  const activeStep = pageIndex !== -1 ? pageIndex : 0

  const existingPhoto: PhotoState | null = ballot && ballot.photoUrl ? { url: ballot.photoUrl, width: 600, height: 600 } : null
  const existingDescription = ballot ? ballot.description : ''
  const existingLocation = ballot ? ballot.googlePlace || null : null
  console.log('existing description', existingDescription)
  const [selectedCategory, setSelectedCategory] = React.useState<string | null>(ballot && ballot.category !== '' ? ballot.category : null)
  const [title, setTitle] = React.useState(ballot && ballot.headline ? ballot.headline : '')
  const [description, setDescription] = React.useState(existingDescription)
  const [descriptionIsEmpty, setDescriptionIsEmpty] = React.useState(existingDescription === '')
  const [location, setLocation] = React.useState<GooglePlace | null>(existingLocation)
  const [photo, setPhoto] = React.useState<PhotoState | null>(existingPhoto)

  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= VIEWPORT_WIDTH_THRESHOLD)
  const descriptionRef = React.createRef<MUIRichTextEditor>()
  const [isPublishing, setIsPublishing] = React.useState(false)

  window.onresize = () => {
    const newIsMobile = window.innerWidth <= VIEWPORT_WIDTH_THRESHOLD
    if (newIsMobile !== isMobile) {
      setIsMobile(newIsMobile)
    }
  }

  function getStepContent(step: number) {
    switch (step) {
      case CATEGORIES_PAGE:
        return getCategoriesContent()
      case TITLE_PAGE:
        return getTitleContent()
      case DESCRIPTION_PAGE:
        return getDescriptionContent()
      case LOCATON_PAGE:
        return getLocationContent()
      case IMAGE_PAGE:
        return getPhotoContent()
      default:
        return 'Unknown step'
    }
  }

  function getCategoriesContent() {
    return (
      <React.Fragment>
        <Typography variant='h5'>Select a category that covers the issue delineated in your proposed ballot.</Typography>
        <Typography style={{ marginTop: 8, marginBottom: 18 }}>
          Selecting a category will allow potential voters to search and find your ballot more easily.
        </Typography>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
          {categories.map(category => {
            const Icon = category.icon
            const isSelected = category.key === selectedCategory
            // const iconStyle = isSelected ? { border: 'solid blue 2px', borderRadius: 6 } : { color: '#777' }
            const className: string = isSelected ? classes.selectedCategory : classes.notSelectedCategory
            return (
              <div key={category.key} style={{ width: 150 }}>
                <Button
                  size='small'
                  key={category.key}
                  style={{ margin: isMobile ? 10 : 20 }}
                  id={category.key}
                  onClick={() => setSelectedCategory(category.key)}
                >
                  <div>
                    <Icon
                      className={className}
                      style={{ width: CATEGORY_ICON_SIZE, height: CATEGORY_ICON_SIZE, padding: 4 }}
                      width='100%'
                      height='100%'
                      // viewBox='0 0 100 100'
                      preserveAspectRatio='none'
                    />
                    <Typography component='div' style={{ fontSize: '.7rem' }}>
                      {category.displayText}
                    </Typography>
                  </div>
                </Button>
              </div>
            )
          })}
        </div>
      </React.Fragment>
    )
  }

  function getTitleContent() {
    return (
      <React.Fragment>
        <Typography variant='h5'>Write a title for your ballot</Typography>
        <Typography style={{ marginTop: 8, marginBottom: 18 }}>
          Provide the voters with a title that will highlight the subject matter in order for the potential voters to quickly understand the
          issue. The issue you are proposing may necessitate delineating the location or type of policy you will be addressing in your
          ballot.
        </Typography>
        <TextField
          id='title'
          label='Title'
          placeholder='Enter a title...'
          variant='outlined'
          value={title}
          onChange={e => setTitle(e.target.value)}
          fullWidth
        />
      </React.Fragment>
    )
  }

  function getDescriptionContent() {
    const editorButtons = ['bold', 'italic', 'underline', 'link', 'numberList', 'bulletList', 'quote']

    return (
      <React.Fragment>
        <Typography variant='h5'>Draft a ballot and explain the issue.</Typography>
        <Typography style={{ marginTop: 8, marginBottom: 18 }}>
          Draft the question that you would like voters to vote on. The question should be simple to make sure the results are clear. Under
          the question, explain why you think the issue needs assistance in the public policy arena. The ballot/issue can address local,
          regional, state, federal or international issues.
        </Typography>
        <div style={{ border: 'solid 1px #ddd', borderRadius: 4 }}>
          <RichTextEditor
            // style={{ minHeight: '12em' }}
            value={description}
            ref={descriptionRef}
            label='Tell us about your ballot...'
            controls={editorButtons}
            // autoFocus
            onChange={(state: EditorState) => {
              const currentContent = state.getCurrentContent()
              setDescription(JSON.stringify(convertToRaw(currentContent)))
              const isEmpty = currentContent.isEmpty() || currentContent.getPlainText() === ''
              console.log('description emtpy', isEmpty)
              setDescriptionIsEmpty(isEmpty)
            }}
          />
        </div>
      </React.Fragment>
    )
  }

  function onLocationChange(place: GooglePlace | null) {
    console.log('location change', place)
    setLocation(place)
  }

  function nullToUndefined<T>(t: T | null) {
    if (t) {
      return t
    }
    return undefined
  }

  function getLocationContent() {
    return (
      <React.Fragment>
        <Typography variant='h5'>Set a location that your ballot involves.</Typography>
        <Typography style={{ marginTop: 8, marginBottom: 18 }}>
          Adding a location will allow voters to find your ballot more readily. This is optional.
        </Typography>
        <GoogleMap initialValue={nullToUndefined(location)} onValueChange={onLocationChange} />
      </React.Fragment>
    )
  }

  function getPhotoContent() {
    return (
      <div className={classes.imageContainer}>
        <Typography variant='h5'>Add a Photo.</Typography>

        <Typography style={{ marginTop: 8, marginBottom: 18 }}>
          Adding a picture can sometimes help to educate the voter on the issue. Photos may include anything relevant to the issue/ballot.
          This is optional.
        </Typography>
        <ImageUpload initialImgSrc={photo ? photo.url : '/image-placeholder.png'} onChange={onPhotoChanged} />
      </div>
    )
  }

  async function onPhotoChanged(originalDataUrl: string, photoWidth: number, photoHeight: number) {
    const resizedDataUrl = await resizeDataUrl(originalDataUrl, photoWidth, photoHeight, PHOTO_SIZE, PHOTO_SIZE)
    setPhoto({ url: resizedDataUrl, width: PHOTO_SIZE, height: PHOTO_SIZE })
  }

  const canContinue = (step: number) => {
    switch (step) {
      case CATEGORIES_PAGE:
        return selectedCategory !== null
      case TITLE_PAGE:
        return title !== ''
      case DESCRIPTION_PAGE:
        return !descriptionIsEmpty
      default:
        return true
    }
  }

  const isStepOptional = (step: number) => {
    return step >= 3
  }

  const handleNext = () => {
    const nextStep = activeStep + 1
    if (nextStep === steps.length) {
      void handlePublish()
      return
    }
    // setActiveStep(prevActiveStep => prevActiveStep + 1)
    navToStep(nextStep)
  }

  const navToStep = (nextStep: number) => {
    if (nextStep < 0 || nextStep >= steps.length) {
      throw new Error('step out of bounds')
    }
    const nextHash = steps[nextStep].hash
    navToHash(nextHash)
  }

  const navToHash = (destHash: string) => {
    props.navTo({ ...props.location, hash: destHash })
  }

  const handleBack = () => {
    // setActiveStep(prevActiveStep => prevActiveStep - 1)
    navToStep(activeStep - 1)
  }

  const handlePreview = () => {
    // console.log('handle preview')
    navToHash('preview')
  }

  function getDraftBallot() {
    const draftBallot: Ballot = {
      headline: title,
      category: selectedCategory as string,
      description,
      googlePlace: nullToUndefined(location),
      photoUrl: photo ? photo.url : undefined,
      draft: true,
      yays: 0,
      nays: 0,
      totalVotes: 0,
      authorId: null,
      authorName: null,
      authorPhotoUrl: null,
      authorSlug: null,
      authorEmail: null,
      createdAt: null,
      updatedAt: null,
    }
    return draftBallot
  }

  function renderPreview() {
    const draftBallot = getDraftBallot()
    return (
      <React.Fragment>
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <Button color='primary' onClick={() => props.navBack()}>
            <KeyboardArrowLeft /> Back To Editing
          </Button>
          <div style={{ flexGrow: 1 }}></div>
        </div>
        <BallotViewPartial ballot={draftBallot} dbVoteState={null} />
      </React.Fragment>
    )
  }

  async function handlePublish() {
    const draftBallot = getDraftBallot()
    console.log('saving draft', draftBallot)
    if (!props.uid) {
      const postAuthActions = [createSetDraftBallotAction(draftBallot), props.createNavToCurrentLocationAction()]
      void dialog
        .alert({
          message: 'You must sign in before publishing a ballot.',
          cancelButton: true,
        })
        .then(result => {
          if (result) {
            props.authenticate(postAuthActions)
          }
        })
    } else {
      console.log('publishing', draftBallot)
      setIsPublishing(true)
      try {
        const id = await props.saveBallot(draftBallot, props.currentUser)
        props.postSaveBallot(id)
      } catch (e) {
        console.error('could not save ballot', e)
        void dialog.alert({
          message: 'Could not save ballot.  Please try again later.',
        })
      }
    }
  }

  function nextButtonText() {
    if (activeStep === LOCATON_PAGE && location === null) {
      return 'Skip'
    }
    if (activeStep === IMAGE_PAGE) {
      return 'Publish'
    }
    return 'Next'
  }

  function previewButtonDisabled() {
    return selectedCategory === null || title === '' || description === ''
  }

  function renderWebStepper() {
    return (
      <React.Fragment>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step, index) => {
            const stepProps: { completed?: boolean } = {}
            const labelProps: { optional?: React.ReactNode } = {}
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography component='div' style={{ width: '100%', textAlign: 'center' }} variant='caption'>
                  Optional
                </Typography>
              )
            }
            return (
              <Step key={step.title} {...stepProps}>
                <StepLabel {...labelProps}>{step.title}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>All steps completed - you&apos;re finished</Typography>
            </div>
          ) : (
            <div>
              <Paper style={{ marginTop: 10, marginBottom: 12, padding: 10 }}>{getStepContent(activeStep)}</Paper>
              <div style={{ display: 'flex' }}>
                <Button onClick={handlePreview} className={classes.button} disabled={previewButtonDisabled() || isPublishing}>
                  Preview
                </Button>
                <div style={{ flexGrow: 1 }}></div>

                <Button disabled={activeStep === 0 || isPublishing} onClick={handleBack} className={classes.button}>
                  Back
                </Button>

                <div style={{ position: 'relative' }}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleNext}
                    className={classes.button}
                    disabled={!canContinue(activeStep)}
                  >
                    {nextButtonText()}
                  </Button>
                  {isPublishing && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }

  function renderMobileStepper() {
    return (
      <React.Fragment>
        <Paper style={{ marginTop: 10, marginBottom: 12, padding: 10 }}>{getStepContent(activeStep)}</Paper>
        <MobileStepper
          steps={steps.length}
          activeStep={activeStep}
          position='static'
          variant='text'
          nextButton={
            <div style={{ position: 'relative' }}>
              <Button size='small' onClick={handleNext} disabled={!canContinue(activeStep) || isPublishing}>
                {nextButtonText()}
                {activeStep !== steps.length - 1 && <KeyboardArrowRight />}
              </Button>
              {isPublishing && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          }
          backButton={
            <Button size='small' onClick={handleBack} disabled={activeStep === 0 || isPublishing}>
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        ></MobileStepper>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {/* <div style={{ flexGrow: 1 }}></div> */}
          <Button onClick={handlePreview} className={classes.button} disabled={previewButtonDisabled() || isPublishing}>
            Preview
          </Button>
        </div>
      </React.Fragment>
    )
  }

  if (hash === 'preview') {
    return renderPreview()
  }
  return <ContentSqueezer>{isMobile ? renderMobileStepper() : renderWebStepper()}</ContentSqueezer>
}

const mapStateToProps = (state: State) => ({
  currentUser: state.user,
  ballot: state.draftBallot,
  uid: getUidOrNull(state.user),
  preview: state.router.location.hash === '#preview',
  createNavToCurrentLocationAction: () => push(state.router.location),
  location: state.router.location,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  navTo: (location: Location) => dispatch(push(location)),
  navBack: () => dispatch(goBack()),
  navToBallotView: (id: string) => dispatch(push(`/ballots/${id}`)),
  authenticate: (postAuthActions: Action[]) => authenticate(dispatch, postAuthActions),
  saveBallot: putBallot,
  postSaveBallot: (id: string) => {
    dispatch(createSetDraftBallotAction(emptyBallot))
    dispatch(push(`/ballots/${id}`))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(BallotCreate)
