import React from 'react'

import firebase from 'firebase/app'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

export interface FirebaseUIProps {
  firebaseAuth?: firebase.auth.Auth
  uiConfig: firebaseui.auth.Config
}

let firebaseUI: firebaseui.auth.AuthUI | undefined
// let uiConfig: firebaseui.auth.Config | undefined

export function setFirebaseUIConfig(config: firebaseui.auth.Config) {
  // uiConfig = config
}

export function getFirebaseUI(): firebaseui.auth.AuthUI {
  if (!firebaseUI) {
    firebaseUI = new firebaseui.auth.AuthUI(firebase.auth())
  }
  return firebaseUI
}

export default class FirebaseUI extends React.Component<FirebaseUIProps> {
  componentDidMount() {
    const ui = getFirebaseUI()
    ui.start('#firebaseui-auth-container', this.props.uiConfig)
  }

  render() {
    return <div id='firebaseui-auth-container' />
  }
}
