import React from 'react'
import Typography, { TypographyProps } from '@material-ui/core/Typography'
import '../utils/animations.css'

interface Props {
  text: string | undefined
}
interface State {
  text: string | undefined
}

export default class AnimatedTypography extends React.Component<Props & TypographyProps, State> {
  state = { text: this.props.text }
  elementRef = React.createRef<HTMLElement>()
  componentDidUpdate() {
    if (this.state.text !== undefined && this.state.text !== this.props.text) {
      const el = this.elementRef.current
      if (el) {
        el.classList.remove('pulse')
        // tslint:disable-next-line: no-unused-expression
        void el.offsetWidth
        el.classList.add('pulse')
      }
    }
    if (this.state.text !== this.props.text) {
      this.setState({ text: this.props.text })
    }
  }

  render() {
    const { text, ...rest } = this.props
    return (
      <Typography {...rest} ref={this.elementRef}>
        {text}
      </Typography>
    )
  }
}
