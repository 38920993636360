import React from 'react'
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

interface Props extends IconButtonProps {
  to?: string
}

const RouteIconButton: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()
  const { to, ...rest } = props
  if (to) {
    console.log('rendering route icon button', to)
    return <IconButton {...rest} onClick={() => dispatch(push(to))} />
  }
  return <IconButton {...props} />
}

export default RouteIconButton
