import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button, { ButtonTypeMap, ButtonProps } from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import { ExtendButtonBase } from '@material-ui/core/ButtonBase'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    // tslint:disable-next-line: object-literal-key-quotes
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

interface RouteButtonProps {
  to: string
}

type Props = ButtonProps<'button', RouteButtonProps>

const RouteButton: ExtendButtonBase<ButtonTypeMap<RouteButtonProps, 'button'>> = (props: Props) => {
  const classes = useStyles()
  return (
    <Button {...props}>
      <Link to={props.to} component={RouterLink} className={classes.buttonStyle}>
        {props.children}
      </Link>
    </Button>
  )
}

export default RouteButton
