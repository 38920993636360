import React from 'react'
import AutoComplete, { AutoCompleteProps } from './AutoComplete'
import { OutlinedTextFieldProps } from '@material-ui/core/TextField'

const GOOGLE_PLACE_FIELDS = ['description', 'place_id', 'types'] as const
type TrimmedGooglePlace = Pick<google.maps.places.AutocompletePrediction, typeof GOOGLE_PLACE_FIELDS[number]>
export interface GooglePlace extends TrimmedGooglePlace {
  mainTerm: string
}

async function queryPlaces(text: string): Promise<GooglePlace[]> {
  return new Promise<GooglePlace[]>((resolve, reject) => {
    const autoCompleteService = new google.maps.places.AutocompleteService()
    const req: google.maps.places.AutocompletionRequest = {
      componentRestrictions: { country: 'us' },
      input: text,
      types: ['geocode'],
    }
    autoCompleteService.getPlacePredictions(req, (result, status) => {
      console.log('omg', status)
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        console.log('places result', result)
        const googlePlaces: GooglePlace[] = result.map(ap => ({
          description: ap.description,
          place_id: ap.place_id,
          types: ap.types,
          mainTerm: ap.terms[0].value,
        }))
        resolve(googlePlaces)
      } else {
        reject(status)
      }
    })
  })
}

export default class GoogleMapsLocationSearch extends React.Component<
  Pick<AutoCompleteProps<GooglePlace>, 'onValueChange' | 'initialValue'> & Omit<OutlinedTextFieldProps, 'variant'>
> {
  // onPlaceSelected = (item: PlaceItem | null) => console.log('item', item)

  render() {
    const { children, ref, ...textFieldProps } = this.props
    // return <AutoComplete valueProvider={queryPlacesWithValue} onValueChange={this.onPlaceSelected} {...textFieldProps} />
    return <AutoComplete valueProvider={queryPlaces} displayTextGetter={place => place.description} {...textFieldProps} />
  }
}
