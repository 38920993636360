import { Action, Dispatch, AnyAction } from 'redux'
import firebase from 'firebase/app'
import 'firebase/auth'
import User, { createUserAuthAction } from '../model/AuthenticatedUser'
// import { initUserCache, UserCache } from './UserCache'
import { getPreferences, putUser, auth, getUser } from '.'
import { push, replace } from 'connected-react-router'

import React from 'react'
import { connect, DispatchProp } from 'react-redux'
import { registerCleanupTask } from '../utils/cleanupManager'
import { Location } from 'history'
import { setPreferencesActionCreator } from '../model/Preferences'
import { State } from '../store/State'
import sleep from '../utils/sleep'
import deepEqual from 'deep-equal'
import { initFirebaseCloudMessaging, removeTokenFromServer } from './firebaseCloudMessaging'
interface AuthProps extends DispatchProp {
  // userCache: UserCache
}

class Auth extends React.Component<AuthProps> {
  constructor(props: AuthProps) {
    super(props)
    const unsub = auth.onAuthStateChanged(this.onAuthStateChanged)
    registerCleanupTask(unsub)
    // initUserCache(props.dispatch)
  }

  onAuthStateChanged = async (fbUser: firebase.User | null) => {
    console.log('onAuthStateChanged', fbUser)
    let user = this.firebaseUserToAuthenticatedUser(fbUser)
    console.log('authenticatedUser user', user)
    // void this.initPreferences()
    if (!user) {
      this.props.dispatch(createUserAuthAction(null))
      return
    }
    const userFromDb = await getUser(user.uid)
    if (!userFromDb) {
      void putUser(user)
    } else {
      user = userFromDb
    }

    this.props.dispatch(createUserAuthAction(user))
    this.handlePostAuth()
    void initFirebaseCloudMessaging(user, this.props.dispatch)
  }

  // let gotPreferences = false
  initPreferences = async () => {
    // if (!gotPreferences) {
    // gotPreferences = true
    const preferences = await getPreferences()
    console.log('got prefs', preferences)
    this.props.dispatch(setPreferencesActionCreator(preferences))
    // }
  }

  handlePostAuth = () => {
    const postAuthActions = getPostAuthActions()
    postAuthActions.forEach(action => this.props.dispatch(action))
  }

  firebaseUserToAuthenticatedUser = (fbUser: firebase.User | null): User | null => {
    if (fbUser === null) {
      return null
    }
    return {
      uid: fbUser.uid,
      email: fbUser.email,
      displayName: fbUser.displayName || fbUser.email,
      photoUrl: fbUser.photoURL,
      fcmTokens: null,
    }
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>
  }
}

export async function signOut() {
  void removeTokenFromServer()
  void firebase.auth().signOut()
}

function setPostAuthActions(actions: Action[]) {
  if (actions.length > 0) {
    window.sessionStorage.setItem('postAuthActions', JSON.stringify(actions))
  } else {
    window.sessionStorage.removeItem('postAuthActions')
  }
}

function getPostAuthActions(): Action[] {
  const postAuthActionsJson = window.sessionStorage.getItem('postAuthActions')
  if (postAuthActionsJson) {
    window.sessionStorage.removeItem('postAuthActions')
    return JSON.parse(postAuthActionsJson) as Action[]
  }
  return []
}

export function authenticate(dispatch: Dispatch, postAuthActions: Action[]) {
  setPostAuthActions(postAuthActions)
  dispatch(push('/signin'))
}

export function signIn(location: Location, dispatch: Dispatch<AnyAction>) {
  setPostAuthActions([replace(location)])
  dispatch(push('/signin'))
}

export function signInToRoute(route: string, dispatch: Dispatch<AnyAction>) {
  setPostAuthActions([replace(route)])
  dispatch(push('/signin'))
}

interface PostAuthAction extends Action {
  value: boolean
}

export function createPostAuthAction(value: boolean) {
  return {
    type: 'POST_AUTH',
    value,
  }
}

export function postAuthReducer(state: boolean = false, action: Action): boolean {
  switch (action.type) {
    case 'POST_AUTH':
      return (action as PostAuthAction).value
    default:
      return state
  }
}

function mapStateToProps(state: State) {
  //   return { userCache: state.userCache }
  return {}
}

export default connect(mapStateToProps)(Auth)
// export default Auth
