import * as React from 'react'
import IconButton from '@material-ui/core/IconButton'

interface EditorButtonProps {
  id?: string
  label: string
  style?: string
  active?: boolean
  icon: JSX.Element
  onClick: any
}

const EditorButton: React.FC<EditorButtonProps> = (props: EditorButtonProps) => {
  return (
    <IconButton
      id={props.id}
      onMouseDown={(e) => {
        e.preventDefault()
        props.onClick(props.style)
      }}
      aria-label={props.label}
      color={props.active ? 'primary' : 'default'}
      size='small'
    >
      {props.icon}
    </IconButton>
  )
}

export default EditorButton
