import React from 'react'
import { connect } from 'react-redux'
import { State } from '../store/State'
import { Link } from 'react-router-dom'
import { Location } from 'history'

const ReactRouterExampleChild: React.FunctionComponent<Location> = (props) => {
  const { pathname, search, hash } = props
  return (
    <div>
      Hello-Child
      <ul>
        <li>
          <Link to='/react-router-example?color=Blue&size=40'>with query string</Link>
        </li>
        <li>
          <Link to='/react-router-example#lovelove'>with hash</Link>
        </li>
      </ul>
      <div>pathname: {pathname}</div>
      <div>search: {search}</div>
      <div>hash: {hash}</div>
    </div>
  )
}

const mapStateToProps = (state: State) => ({
  // pathname: state.router.location.pathname,
  // search: state.router.location.search,
  // hash: state.router.location.hash,
  ...state.router.location,
})

const ConnectedReactRouterExampleChild = connect(mapStateToProps)(ReactRouterExampleChild)

const ReactRouterExample = () => (
  <div>
    <div>Explore</div>
    <ConnectedReactRouterExampleChild />
  </div>
)

export default ReactRouterExample
