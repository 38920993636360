import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const usesStyles = makeStyles(theme => ({
  outer: {
    display: 'flex',
    // flexDirection: 'column',
    flexGrow: 1,
    // border: '3px green dotted',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
  },
  inner: {
    display: 'flex',
    // backgroundColor: '#eee',
    // border: 'solid 1px red',
  },
  content: {
    maxWidth: 800,
    minWidth: 300,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    // border: '3px red dotted',
    // overflow: 'auto',
    // WebkitOverflowScrolling: 'touch',
  },
  spacer: {
    flexGrow: 1,
  },
}))

// interface ContentContainerProps {}

const ContentSqueezer: React.FunctionComponent<{}> = props => {
  const classes = usesStyles(props)
  return (
    <div className={classes.outer}>
      {/* <div className={classes.inner}> */}
      <div className={classes.spacer}></div>
      <div className={classes.content}>{props.children}</div>
      <div className={classes.spacer}></div>
      {/* </div> */}
    </div>
  )
}

export default ContentSqueezer
