import React from 'react'
import { State } from '../store/State'
import { connect, useDispatch } from 'react-redux'
import { CurrentUser } from '../model/AuthenticatedUser'
import { CurrentUserAvatar, MenuMouseEvent } from './UserAvatar'
import SignInButton from './SignInButton'
import { signOut } from '../firebase/auth'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { replace } from 'connected-react-router'

interface UserAppBarItemProps {
  user: CurrentUser
  isOnSignInPage: boolean
}

const AppBarUser: React.FunctionComponent<UserAppBarItemProps> = props => {
  // console.log('AppBarUser', props)

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)
  const dispatch = useDispatch()
  function handleClose() {
    setAnchorEl(null)
  }

  function handleMenu(event: MenuMouseEvent) {
    setAnchorEl(event.currentTarget)
  }

  function handleSignOut() {
    handleClose()
    console.log('sign out')
    void signOut()
    // dispatch(replace('/'))
  }

  function handleProfile() {
    handleClose()
    dispatch(replace('/profile'))
  }

  const open = Boolean(anchorEl)

  switch (props.user) {
    case null:
      return props.isOnSignInPage ? null : <SignInButton color='inherit'>Sign In</SignInButton>
    case 'unknown':
      return null
    default:
      return (
        <React.Fragment>
          <CurrentUserAvatar onClick={handleMenu} id='currentUserAvatar' />
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleProfile}>Profile</MenuItem>
            <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
          </Menu>
        </React.Fragment>
      )
  }
}

const mapStateToProps = (state: State): UserAppBarItemProps => ({
  user: state.user,
  isOnSignInPage: state.router.location.pathname === '/signin',
})

export default connect(mapStateToProps)(AppBarUser)
