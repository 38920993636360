import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import './index.css'
import App from './App'
import { history, configureStore } from './store'
// import * as serviceWorker from './serviceWorker'
import theme from './theme'
import './firebase'
import { AlertDialogProvider } from './components/AlertDialog'
import Auth from './firebase/auth'
const store = configureStore()

const render = (Component: typeof App) => {
  return ReactDOM.render(
    <AlertDialogProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Auth>
            <CssBaseline />
            <Component history={history} />
          </Auth>
        </ThemeProvider>
      </Provider>
    </AlertDialogProvider>,
    document.getElementById('root'),
  )
}

render(App)

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    render(NextApp)
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// if (window.location.protocol === 'file:') {
//   serviceWorker.unregister()
// } else {
//   serviceWorker.register()
// }

// serviceWorker.unregister()

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for (let registration of registrations) {
      console.log('registration ', registration.scope)
      if (registration.scope.endsWith('firebase-cloud-messaging-push-scope')) {
        void registration.unregister()
      }
    }
  })
}
