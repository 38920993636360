import { LocationListener } from 'history'
import { Middleware } from 'redux'
import { State } from '../store/State'

export const routerListener: Middleware<{}, State> = (api) => (next) => (action) => {
  next(action)
  if (action.type && action.type === '@@router/LOCATION_CHANGE') {
    console.log('location change', api.getState().router)
  }
}
