import firebase from 'firebase/app'
import 'firebase/messaging'
import 'firebase/functions'
import User from '../model/AuthenticatedUser'
import { publicVapidKey } from '.'
import { AnyAction, Dispatch } from 'redux'
import { push } from 'connected-react-router'
import { promises } from 'dns'

let fcmToken: string | null = null

const functions = firebase.functions()
const sendFcmToken = functions.httpsCallable('sendFcmToken')
const removeFcmToken = functions.httpsCallable('removeFcmToken')

// tslint:disable-next-line: prefer-const
const cordovaFirebasePlugin = (window as any).FirebasePlugin

interface MessagingApi {
  init: () => Promise<void>
  requestPermission: () => Promise<NotificationPermission>
  getToken: () => Promise<string | null>
  onTokenRefresh: (handler: (token: string) => void) => void
  onMessage: (handler: (payload: any) => void) => void
}

let useServiceWorkerCalled = false

class MessagingApiWeb implements MessagingApi {
  messaging: firebase.messaging.Messaging
  constructor() {
    console.log('instantiating MessagingApiWeb')
    this.messaging = firebase.messaging()
  }

  async init() {
    if (!useServiceWorkerCalled) {
      console.log('registering sw')
      const registration = await navigator.serviceWorker.register('/sw.js')
      console.log('sw registered', registration)

      this.messaging.useServiceWorker(registration)
      useServiceWorkerCalled = true
      console.log('calling usePublicVapidKey')
      this.messaging.usePublicVapidKey(publicVapidKey)
    }
  }

  requestPermission = () => Notification.requestPermission()
  onTokenRefresh = (handler: (token: string) => void) => this.messaging.onTokenRefresh(handler)
  getToken = () => {
    console.log('calling firebase messaging getToken')
    return this.messaging.getToken()
  }
  onMessage = (handler: (payload: any) => void) => this.messaging.onMessage(handler)
}

// tslint:disable-next-line: max-classes-per-file
class MessagingApiCordova implements MessagingApi {
  constructor() {
    console.log('instantiating MessagingApiCordova')
  }
  requestPermission = () => {
    console.log('MessagingApiCordova requestPermission')
    return new Promise<NotificationPermission>(resolve => {
      cordovaFirebasePlugin.hasPermission((hasPermission: boolean) => {
        console.log('has permission', hasPermission)
        if (hasPermission) {
          resolve('granted')
        } else {
          cordovaFirebasePlugin.grantPermission(
            () => resolve('granted'),
            () => resolve('denied'),
          )
        }
      })
    })
  }

  async init() {}

  onTokenRefresh = (handler: (token: string) => void) => {
    console.log('MessagingApiCordova onTokenRefresh')
    cordovaFirebasePlugin.onTokenRefresh(
      (token: string) => handler(token),
      (error: any) => console.error('onTokenRefresh', error),
    )
  }
  getToken = () => {
    console.log('MessagingApiCordova getToken')
    return new Promise<string | null>(resolve => {
      cordovaFirebasePlugin.getToken(
        (token: string) => resolve(token),
        () => resolve(null),
      )
    })
  }

  onMessage = (handler: (payload: any) => void) => {
    console.log('MessagingApiCordova onMessage')
    cordovaFirebasePlugin.onMessageReceived(
      (payload: any) => handler(payload),
      (error: any) => console.error('onMessage', error),
    )
  }
}

export function isMessagingSupported() {
  console.log('isMessagingSupported', firebase.messaging.isSupported(), !!cordovaFirebasePlugin)
  return cordovaFirebasePlugin || firebase.messaging.isSupported()
}

function isMobile() {
  return !!cordovaFirebasePlugin
}

async function createMessagingApi(): Promise<MessagingApi> {
  const api: MessagingApi = isMobile() ? new MessagingApiCordova() : new MessagingApiWeb()
  console.log('initing messaging api')
  await api.init()
  console.log('done initing messaging api')
  return api
}

let messagingApi: MessagingApi

void createMessagingApi().then(api => (messagingApi = api))

window.addEventListener('load', function() {
  console.log('messaging on load')
})

export async function initFirebaseCloudMessaging(dbUser: User, dispatch: Dispatch<AnyAction>) {
  if (!isMessagingSupported()) {
    return
  }

  const permResult = await messagingApi.requestPermission()
  console.log('notification permission', permResult)
  if (permResult === 'denied') {
    return
  }

  console.log('getting push token')
  fcmToken = await messagingApi.getToken()
  console.log('got push token', fcmToken)
  if (fcmToken) {
    const tokenExists = dbUser.fcmTokens && dbUser.fcmTokens.includes(fcmToken)
    if (tokenExists) {
      console.log('fcmToken exists')
    } else {
      if (!dbUser.fcmTokens) {
        dbUser.fcmTokens = [fcmToken]
      } else {
        dbUser.fcmTokens.push(fcmToken)
      }
      await sendTokenToServer(null, fcmToken)
      console.log('sent token to server')
    }
  }

  messagingApi.onMessage(payload => {
    console.log('Message received in browser. ', JSON.stringify(payload))
    if (payload.tap === 'background') {
      if (payload.slug) {
        console.log('found slug', payload.slugZ)
        dispatch(push(`/ballots/${payload.slug}`))
      }
    }
  })

  const onTokenRefresh = (newToken: string) => {
    console.log('token refreshed ', newToken)
    const oldToken = fcmToken
    const tokenList = dbUser.fcmTokens
    if (oldToken && tokenList) {
      const i = tokenList.indexOf(oldToken)
      if (i !== -1) {
        tokenList[i] = newToken
      }
    }
    if (oldToken !== newToken) {
      fcmToken = newToken
      void sendTokenToServer(oldToken, newToken)
    }
  }
  messagingApi.onTokenRefresh(onTokenRefresh)
}

async function sendTokenToServer(oldToken: null | string, newToken: string) {
  const funcResult = await sendFcmToken({
    oldToken,
    newToken,
    isMobile: isMobile(),
  })
  console.log('subscribeToNotifications result', funcResult)
}

export async function removeTokenFromServer() {
  if (fcmToken) {
    const messaging = firebase.messaging()
    const funcResultPromise = removeFcmToken({ fcmToken })
    const deletePromise = messaging.deleteToken(fcmToken)
    console.log('unsubscribeFromNotifications result', await funcResultPromise)
    console.log('delete token result', await deletePromise)
  }
}
