import { combineReducers, Middleware, Action, ActionCreator, Reducer } from 'redux'
import BallotQuery, { ballotQueryReducer, ballotQueryActionTypes, initialBallotQuery } from './BallotQuery'
import { State } from '../store/State'
import { putPreferences } from '../firebase'

export interface Preferences {
  ballotQuery: BallotQuery
}

export const initialPreferences: Preferences = {
  ballotQuery: initialBallotQuery,
}

const allPreferencesActionTypes = new Set<string>()

ballotQueryActionTypes.forEach((item) => allPreferencesActionTypes.add(item))

export const preferencesPersistence: Middleware<{}, State> = (api) => (next) => (action) => {
  next(action)
  if (allPreferencesActionTypes.has(action.type)) {
    const prefs = api.getState().preferences
    if (prefs) {
      void putPreferences(prefs)
    }
  }
}

interface SetPreferencesAction extends Action {
  preferences: Preferences
}

export const setPreferencesActionCreator: ActionCreator<SetPreferencesAction> = (preferences: Preferences) => ({
  type: 'SET_PREFERENCES',
  preferences,
})

export const childrenReducer = combineReducers({ ballotQuery: ballotQueryReducer })

export const preferencesReducer: Reducer<Preferences | null> = (state = null, action) => {
  switch (action.type) {
    case 'SET_PREFERENCES':
      return (action as SetPreferencesAction).preferences
  }
  // child reducers shouldn't come in before we have a non-null preferences
  if (!state) {
    return null
  }
  const { ballotQuery } = state
  const rv: Preferences = {
    ...state,
    ...childrenReducer({ ballotQuery }, action),
  }
  return rv
}
