import React from 'react'
import BallotQuery from '../model/BallotQuery'
import BallotListView from './BallotListView'

interface BallotListViewForUserProps {
  userId?: string
}

const BallotListViewForUser: React.FunctionComponent<BallotListViewForUserProps> = props => {
  const ballotQuery: BallotQuery = {
    sortBy: 'createdAt',
    direction: 'desc',
    author: props.userId,
  }
  return <BallotListView ballotQuery={ballotQuery} />
}

export default BallotListViewForUser
