import React from 'react'
import Typography from '@material-ui/core/Typography'

import BallotQuery from '../model/BallotQuery'
import { connect } from 'react-redux'
import { State } from '../store/State'
import { CurrentUser, UNKNOWN_AUTH } from '../model/AuthenticatedUser'
import RouteButton from '../components/RouteButton'
import SignInButton from '../components/SignInButton'
import BallotListView from '../components/BallotListView'
import ContentSqueezer from '../components/ContentSqueezer'

interface MyBallotsProps {
  currentUser: CurrentUser
}

const MyBallots: React.FunctionComponent<MyBallotsProps> = props => {
  function renderEmptyBallots() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 20 }}>
        <div style={{ margin: 10 }}>
          <Typography>You haven't created any ballots yet.</Typography>
        </div>
        <div style={{ margin: 10 }}>
          <RouteButton to='/create-ballot' variant='contained'>
            Create Ballot
          </RouteButton>
        </div>
      </div>
    )
  }

  function renderMyBallotsWhileUnauthenticated() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 20 }}>
        <div style={{ margin: 10 }}>
          <Typography>You must sign in before viewing your list of Ballots.</Typography>
        </div>
        <div style={{ margin: 10 }}>
          <SignInButton variant='contained'>Sign In</SignInButton>
        </div>
      </div>
    )
  }

  if (props.currentUser === UNKNOWN_AUTH) {
    return null
  }

  if (props.currentUser === null) {
    return renderMyBallotsWhileUnauthenticated()
  }

  const ballotQuery: BallotQuery = {
    sortBy: 'createdAt',
    direction: 'desc',
    author: props.currentUser.uid,
  }

  return (
    <ContentSqueezer>
      <BallotListView ballotQuery={ballotQuery} renderEmptyList={renderEmptyBallots} />
    </ContentSqueezer>
  )
}

const mapStateToProps = (state: State) => ({
  currentUser: state.user,
})

export default connect(mapStateToProps)(MyBallots)
